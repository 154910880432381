/* PHILOSOPHY STYLES */

.philosophy {
    width: 100%;
    height: 100%;
   
    .philosophy-inner {
        margin-top: 30px;
        padding: 0px 5vw;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .philosophy-title {
            align-self: flex-start;
            text-transform: uppercase;
            margin: 20px 0px;
            font-size: 1.2rem;
            font-weight: bold;
            margin-top: 40px;
            color: #0a415d;
        }
        .philosophy-text {
            align-self: flex-start;
            p {
                color: #0a415d ;
                font-weight: 300;
                font-size: 1rem;
                text-align: justify;
                // margin-bottom: 60px;
            }
            ul {
                margin-left: 40px;
                color: #0a415d;
                font-weight: 300;
                font-size: 1rem;
            }
        }
        .phi-item-container {
            display:flex;
            width: 100%;
            align-items:flex-start;
            justify-content:flex-start;

            .phi-item-icon {
                width: 50px;
            }
            .phi-item-title {
                align-self: flex-start;
                text-transform: uppercase;
                margin: 20px 0px;
                font-size: 1.2rem;
                font-weight: bolder;
                color: #0a415d;
            }
            .phi-item-text {
                p {
                    color: #0a415d ;
                font-weight: 300;
                font-size: 1rem;
                text-align: justify;
                margin-bottom: 60px;
                }
            }
        }
        #phi-item-dropdown {
            margin-right: 2rem;
        }
        .dropdown-area{
            width: 100%;
            display: flex;
            margin-right:2rem;
        }

        .institutional-docs__container {

            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
           

            .institutional-docs__item {
                flex: 1;
                display: flex;
                // justify-content: center;
                // position: relative;
                // background: #BADA55; 
                justify-content: center;
                
                // border-radius: 50%;
                // margin-right: -20px;
                &::before{
                    // display: block;
                    // padding-top: 100%;
                    display: flex;
                    justify-content: center;
                }

                .institutional-docs__item-content {
                    font-size: 8;
                    transition: all 200ms ease-in-out;
                    height: 8rem;
                    aspect-ratio: 1;
                    margin-left: 1rem;
                    margin-bottom: 1rem;
                    max-width: 8rem;
                    align-self: center;
                    flex: 1;
                    font-weight: bolder;
                    background: #0a415d;
                    color: #fee860;
                    border-radius: 50%;
                    font-size: 1em;
                    font-weight: bold;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    // margin-right: -20px;
                    text-align: center;
                }


                .institutional-docs__item-content:hover {
                    & {
                        border: thick solid white;
                        outline: thick solid #fee860;
                        background-color: #fee860;
                        color: #0a415d;
                    }
                }
            }
        
        }
      
        
    }

   
  
}

/* PHILOSOPHY RESPONSIVE */
@media (max-width: 1200px) {
    .philosophy-inner {
        padding: 0px 5vw !important;
    }
}
@media (max-width: 805px) {
    .color-3 {
        background-color: #0a415d !important;
        color: white !important;
    }
}
