/* ESTUDENTS BOARD STYLES */

.students-board {
    margin-bottom: 15vh;
    width: 100%;
    height: 100%;
    .students-board-inner {
        margin-top: 45px;
        // padding: 0px 15vw;
        padding: 0px 15vw 20px 15vw;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: #0a415d;
        p{
            color: #0a415d;
        }
        div > div{
            justify-content: center;
        }

        .students-board-title {
            text-transform: uppercase;
            margin: 20px 0px;
            font-size: 1.2rem;
            font-weight: bold;
            align-items: flex-start;
            
        }
        .students-board-subtitle {
            margin-top: 20px;
            font-size: 1.2rem;
            font-weight: bold;
        }
        .students-board-text {
            font-weight: lighter;
            font-size: 1rem;
            text-align: justify;
        }
        .students-board-courses-tiles{
            margin-top: 30px;
            width: 100%;
            height: 100%;
            .row{
                margin: 0;
            }
        }
    }
}

/* DIRECTORS BOARD RESPONSIVE */
// @media(max-width: 1200px) {
//     .philosophy-inner {
//         padding: 0px 5vw !important;
//     }
// }
