/* Modify slider's arrows style */
.main-slider {
    //margin-top: var(--main-slider-margin-top); /* Hello I'm the maco*/
    .awssld__bullets{
        bottom: -25px;
    }
    .awssld__bullets button{
        z-index: 9;
        width: 10px;
        height: 10px;
        opacity: 0.2;
        margin: 3px;
    }
    .awssld {
        height: var(--main-slider);
        // border-bottom: 2px solid #0a415d;
    }
    .awssld__timer {
        opacity: 0 !important;
        pointer-events: none !important; /* prevent events (mouse clicking etc.) */
    }
    .awssld__controls button {
        top: calc(60% - (0.5 * var(--control-button-height-n)));
    }
    .awssld__controls__arrow-left::before,
    .awssld__controls__arrow-left::after,
    .awssld__controls__arrow-right::before,
    .awssld__controls__arrow-right::after {
        background-color: var(--organic-arrow-color-n) !important;
        width: var(--organic-arrow-thickness-n) !important;
        border-radius: var(--organic-arrow-border-radius-n) !important;
    }
    .awssld__container {
        padding-bottom: var(--slider-height-percentage-n) !important;
    }
    .slider-link {
        position: relative;
        display: block;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }
    // .awssld__content {
    //     img {
    //         object-fit: fill !important;
    //     }
    // }

   
}

@media (min-width: 780px) {
    .main-slider {
        margin-top: var(--main-slider-margin-top);
    }
}

