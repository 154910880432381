/* ADMINISTRATIVE BOARD STYLES */

.administrative-board {
    margin-bottom: 15vh;
    padding: 0px 15vw;
    width: 100%;
    height: 100%;
    color: #0a415d;
    .administrative-board-inner {
        margin-top: 30px;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        // align-items: center;

        .administrative-board-title {
            text-transform: uppercase;
            margin: 20px 0px;
            font-size: 1.2rem;
            font-weight: bold;
            align-self: flex-start;
        }
        .administrative-board-subtitle {
            margin-top: 20px;
            font-size: 1.2rem;
            font-weight: bold;
        }
        .administrative-board-text {
            // font-weight: lighter;
            font-size: 1rem;
            text-align: justify;
            white-space: pre-line;
            margin-bottom: 20px;
        }

    }
    // .administrative-board-profiles-inner {
       
    //     width: 100%;
    //     height: 100%;
    //     // padding: 5px 0px;
    //     overflow: hidden;
        
    //     div > div{
    //         justify-content: center;
    //     }
    // }
}

/* DEPARTMENTS RESPONSIVE */
@media (max-width: 1400px) {
    .administrative-board {
        padding:0 2vw
    }
}