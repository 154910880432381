.video-player-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    .react-player {
        overflow: hidden;
        z-index: 9;
        position: relative;
    }
}
