.row.aboutus-body {
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 12%;
}
.aboutus-body {
    width: 100%;
    height: 100%;
}

// /*                   GENERAL EFFECTS                     */ 
// --------------- HISTORY SUBAREA EFFECT --------------------
.history-subarea-enter {
    opacity: 0;
    transform: translateY(-100%);
}
.history-subarea-enter-active {
    opacity: 1;
    transform: translateY(-100%);
    transition: opacity 0.5s, transform 0.5s ease-out 0s;
}
.history-subarea-exit-active {
    opacity: 1;
    transform: translateY(100%);
}
.history-subarea-exit {
    opacity: 0;
    transform: translateY(-100%);
    transition: opacity 0.5s, transform 0.5s ease-in 0s;
}

// --------------- PHILOSOPHY SUBAREA EFFECT --------------------
.philosophy-subarea-enter {
    opacity: 0;
    transform: translateY(-100%);
}
.philosophy-subarea-enter-active {
    opacity: 1;
    transform: translateY(0%);
    transition: opacity 0.5s, transform 0.5s ease-out 0s;
}
.philosophy-subarea-exit {
    opacity: 0;
    transform: translateY(0%);
    transition: opacity 0.5s, transform 0.5s ease-in 0s;
}
.philosophy-subarea-exit-active {
    opacity: 0;
    transform: translateY(-100%);
}

// --------------- COMMITMENT SUBAREA EFFECT --------------------
.commitment-subarea-enter {
    opacity: 0;
    transform: translateY(-100%);
}
.commitment-subarea-enter-active {
    opacity: 1;
    transform: translateY(0%);
    transition: opacity 0.5s, transform 0.5s ease-out 0s;
}
.commitment-subarea-exit {
    opacity: 0;
    transform: translateY(0%);
    transition: opacity 0.5s, transform 0.5s ease-in 0s;
}
.commitment-subarea-exit-active {
    opacity: 0;
    transform: translateY(-100%);
}
// --------------- ALLIES SUBAREA EFFECT --------------------
.allies-subarea-enter {
    opacity: 0;
    transform: translateY(-100%);
}
.allies-subarea-enter-active {
    opacity: 1;
    transform: translateY(0%);
    transition: opacity 0.5s, transform 0.5s ease-out 0s;
}
.allies-subarea-exit {
    opacity: 0;
    transform: translateY(0%);
    transition: opacity 0.5s, transform 0.5s ease-in 0s;
}
.allies-subarea-exit-active {
    opacity: 0;
    transform: translateY(-100%);
}

// --------------- VIRTUAL TOOLS SUBAREA EFFECT --------------------
.vitual-tools-subarea-enter {
    opacity: 0;
    transform: translateY(-100%);
}
.vitual-tools-subarea-enter-active {
    opacity: 1;
    transform: translateY(0%);
    transition: opacity 0.5s, transform 0.5s ease-out 0s;
}
.vitual-tools-subarea-exit {
    opacity: 0;
    transform: translateY(0%);
    transition: opacity 0.5s, transform 0.5s ease-in 0s;
}
.vitual-tools-subarea-exit-active {
    opacity: 0;
    transform: translateY(-100%);
}

// --------------- VIRTUAL TOOLS SUBAREA EFFECT --------------------
.vitual-tools-subarea-enter {
    opacity: 0;
    transform: translateY(-100%);
}
.vitual-tour-subarea-enter-active {
    opacity: 1;
    transform: translateY(0%);
    transition: opacity 0.5s, transform 0.5s ease-out 0s;
}
.vitual-tour-subarea-exit {
    opacity: 0;
    transform: translateY(0%);
    transition: opacity 0.5s, transform 0.5s ease-in 0s;
}
.vitual-tour-subarea-exit-active {
    opacity: 0;
    transform: translateY(-100%);
}

// --------------- DEPARTMENTS SUBAREA EFFECT --------------------
.departments-subarea-enter {
    opacity: 0;
    transform: translateY(-100%);
}
.departments-subarea-enter-active {
    opacity: 1;
    transform: translateY(0%);
    transition: opacity 0.5s, transform 0.5s ease-out 0s;
}
.departments-subarea-exit {
    opacity: 0;
    transform: translateY(0%);
    transition: opacity 0.5s, transform 0.5s ease-in 0s;
}
.departments-subarea-exit-active {
    opacity: 0;
    transform: translateY(-100%);
}