/* Cocurriculars carrousel styles */

.co-curriculars-carousel {
    height: 480px;
    width: 100%;
    padding: 100px 0 30px 0;
    background-color: rgba(255, 255, 255, 0.91);

    button.react-multiple-carousel__arrow {
        z-index: 9;
        transform: translateY(-60px);
    }
    .co-curriculars-inner {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        transition: all 0.3s ease-in;

        .co-curriculars-header {
            padding: 0 10px 0 15px;
            font-size: 1.5rem;
            color: var(--cocurriculars-header);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            span {
                white-space: nowrap;
                background-color: #0a415d;
                color: white;
                padding: 0px 5px;
            }
            div.bottom-margin {
                height: 130px;
                width: 100%;
            }
        }
        .co-curriculars-footer {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            margin-top: 20px;
            margin-bottom: 50px;

            .co-curriculars-footer-button {
                position: relative;
                z-index: 3;
                .co-curriculars-button {
                    align-self: center;
                    width: 25vw;
                    white-space: pre-wrap;
                    height: 60px;
                    border-radius: 60px;
                    background-color: var(--bg-cocurriculars-button);
                }
            }
            .co-curriculars-footer-figures {
                width: 100%;
                position: absolute;
                z-index: 2;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                padding: 0 1vw 0 8vw;
            }
        }
    }
    .co-curricular {
        height: 100px;
    }
    .react-multi-carousel-list {
        width: 100%;
        height: 100%;
    }
    .react-multi-carousel-item,
    .react-multi-carousel-item--active {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        height: 100%;
    }
    .cocurricular-card {
        overflow: hidden;
        border: none;
        justify-content: center;
        align-items: center;
        
        .card-title {
            text-align: center;
            color: var(----cocurriculars-header);
            text-transform: uppercase;
            white-space: pre-wrap;
            user-select: none;
        }
        .card-body {
            text-align: justify;
            color: var(--cocurriculars-card);
            text-transform: lowercase;
        }
        .cocurricular-card-header {
            z-index: 9;
            position: relative;
            overflow: hidden;
            background-color: #c2dcea;
            width: 200px;
            height: 200px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            
            img {
                // color: white;
                user-select: none;
                z-index: 10;
                height: 120px;
                width: 120px;
                // filter: invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg) brightness(0%) contrast(100%);
            }
            
            .cocurricular-card-desciption {
                color: rgba(0, 0, 0, 0.1);
                background-color: rgba(0, 0, 0, 0.1);
                position: absolute;
                z-index: 8;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                // transform: translate(50%, 50%);
                transition: all 400ms ease;
                white-space: pre-line;
                justify-content: center;
                padding: 0px 15px;
                display: flex;
                justify-content: center;
                align-items: center;

                p {
                    opacity: 0.0;
                    font-size: 12px;
                    text-align: center;
                    font-family: "AleoLight";
                    font-weight: 50;
                }
                

            }
        }
        .cocurricular-card-header:hover {
            
            .cocurricular-card-desciption {
                color: white;
                background-color: #599abb;
                z-index: 999;
                overflow-wrap: break-word;
                p {
                    opacity: 1;
                    user-select: none;
                    line-height: 100%;
                    
                }
                // i::before {  
                //        content: '';
                //         float: left;
                //         height: 100%;
                //         width: 50%;
                //         background-color: red;
                //         shape-outside: radial-gradient(farthest-side at right, transparent calc(100% -2px), #fff 0);
                //   }

                //   i {
                //         float: right;
                //         shape-outside: radial-gradient(farthest-side at left, transparent calc(100% -20px), #fff 0);
                //     }
            }

        }
    }
}

.cocurricular-card-text {
    font-size: 0.9rem;
}

@media (max-width: 500px) {
    // .titles{
    //     font-size: 2.5rem !important;
    // }
    .co-curriculars-button {
        width: 45vw !important;
    }
    .co-curriculars-footer-figures {
        display: none !important;
    }
}
@media (max-width: 800px) {
    .co-curriculars-inner {
        flex-direction: column !important;
    }
}

@media (max-width: 800px) {
    .co-curriculars-carousel {
        height: 700px;
        width: 100%;
        .react-multi-carousel-list{
            height: 350px;
        }
        .bottom-margin{
            height: 50px !important;
        }
    }
}

// @mixin circle($radius){
//     width: $radius*2;
//     height: $radius*2;
//     border-radius: $radius;
//     &::before{
//       content: '';
//       height: 100%;
//       width: 50%;
//       float: left;
//       shape-outside: polygon(
//         0 0,
//         100% 0,
//         60% 4%,
//         40% 10%,
//         20% 20%,
//         10% 28.2%,
//         5% 34.4%,
//         0 50%,
//         5% 65.6%,
//         10% 71.8%,
//         20% 80%,
//         40% 90%,
//         60% 96%,
//         100% 100%,
//         0% 100%
//       );
//     }
//     > span::before{
//       content: '';
//       height: 100%;
//       width: 50%;
//       float: right;
//       shape-outside: polygon(
//         100% 0,
//         0 0,
//         40% 4%,
//         60% 10%,
//         80% 20%,
//         90% 28.2%,
//         95% 34.4%,
//         100% 50%,
//         95% 65.6%,
//         90% 71.8%,
//         80% 80%,
//         60% 90%,
//         40% 96%,
//         0 100%,
//         100% 100%
//       );
//     }
//   }
  
//   .circle{
//     background: #accede;
//     text-align: center;
//     @include circle(6rem);
//   }

// .cocurricular-card-desciption {
//     --s:250px;
//     --p:15px;

//     height: var(--s);
//     width: var(--s);
//     border-radius: 50%;
//     background: #333;
//     color: #fff;
//     margin: 30px auto;
//     text-align: justify;
// }
// .cocurricular-card-desciption i,
// .cocurricular-card-desciption::before {
//     content: '';
//     float: left;
//     height: 100%;
//     width: 50%;
//     shape-outside: radial-gradient(farthest-side at right, transparent calc(100% - var(--p)), #fff 0);
// }

// .box i {
//     float: right;
//     shape-outside: radial-gradient(farthest-side at left, transparent calc(100% - var(--p)), #fff 0);
// }