/* DEPARTMENTS STYLES */

.vitual-tools {
    width: 100%;
    height: 100%;
    .vitual-tools-inner {
        margin-top: 30px;
        margin-bottom: 15vh;
        padding: 0px 15vw;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .vitual-tools-title {
            text-transform: uppercase;
            margin: 20px 0px;
            font-size: 1.2rem;
            font-weight: bold;
        }
        .vitual-tools-subtitle {
            margin-top: 20px;
            font-size: 1.2rem;
            font-weight: bold;
        }
        .vitual-tools-text {
            p {
                color: black;
                font-weight: 300;
                font-size: 1rem;
                text-align: justify;
                white-space: pre-line;
            }
        }
        .areas {
            width: 100%;
            height: 100%;
            padding: 0px 0px;
            margin-top: 15px;
            position: relative;
            z-index: 8;
            .row {
                width: 100%;
                margin: 0;
                justify-content: center;
            }
            .col-card {
                // width: 100%;
                height: 100%;
                display: flex;
                padding: 10px 5px;
                justify-content: center;
                align-items: center;
            }
            .area-title {
                position: absolute;
                background-color: white;
                padding: 0px 10px;
                top: 0%;
                left: 50%;
                transform: translate(-50%, -50%);
                text-transform: uppercase;
            }
            .area-card-container {
                display: inline-block;
                margin: 0 auto;
                padding: 0 12px;
                perspective: 900px;
                text-align: center;
                // border-radius: 5px;
            }
            .area-card,
            .front,
            .back {
                // margin-top: 20px;
                width: 220px;
                height: 95px;
                transition: all 0.2s ease-in-out;
            }
            .area-card {
                margin-top: 25px;
                position: relative;
                overflow: hidden;
                // border-left: 1.5px solid #0a415d;
                // border-bottom: 1.5px solid #0a415d;
            }
            .front,
            .back {
                position: absolute;
                top: 0;
                left: 0;
                // border-radius: 5px;
            }
            .front {
                background: white;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                color: white;
                .image{
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding-left: 10px;
                    padding-bottom: 10px;
                    // transition: all 0.2s ease-in-out;
                    img {
                        height: auto;
                        width: 100%;
                    }
                }
                .image-moodle {
                    img {
                        height: auto;
                        width: 70%;
                    }
                }
                .image-cloudlabs {
                    img {
                        height: auto;
                        width: 80%;
                    }
                }
                .image-edusystem {
                    img {
                        height: auto;
                        width: 90%;
                    }
                }
            }
            .back {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-around;
                padding: 5px 10px;
                text-align: center;
                font-size: 12px;
                word-spacing: 0.5px;
                background-color: #ffe25d;
                color: #0a415d;
                cursor: default;
                transition: all 0.6s ease;

                p {
                    margin-top: 10px;
                    text-align: center;
                    padding: 0px 10px;
                    font-weight: 700 !important;
                }
                button {
                    line-height: 1;
                    font-weight: 700 !important;
                }
            }
            .area-card-container:hover .area-card .back {
                transform: translateY(0);
            }
            .back {
                transform: translateY(100%);
            }

            .icon {
                font-size: 1.3rem;
            }
            .title {
                text-transform: uppercase;
            }
            .text {
                white-space: pre-wrap;
                text-align: justify;
            }
            .area-card:hover {
                cursor: pointer;
                border: none;
                transform: scale(1.05);
                box-shadow: -5px 5px 15px 0px rgba(0, 0, 0, 0.75);
                -webkit-box-shadow: -5px 5px 15px 0px rgba(0, 0, 0, 0.75);
                -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.75);
            }
        }
    }
}
