/* MNU STYLES */

.mnu {
    width: 100%;
    height: 100%;
    margin-top: 50px;
    .mnu-inner {
        margin-top: 30px;
        padding: 0px 15vw;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .mnu-title {
            margin: 20px 0px;
            font-size: 1.2rem;
            font-weight: bold;
            text-transform: uppercase;
            color: #0a415d;
        }
        .mnu-text {
            p {
                color: #0a415d;
                font-weight: 300;
                font-size: 1rem;
                text-align: justify;
                white-space: pre-line;
            }
        }
    }
}
