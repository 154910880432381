.construction {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .construction-button {
        button {
            margin: 20px 0px;
            font-weight: 300;
            color: #0a415d;
            background-color: transparent;
            font-size: 1.2em;
            text-decoration: none;
            border: 1px solid #0a415d;
            padding: 0.5em;
            border-radius: 3px;
            position: relative;
            transition: all 0.3s linear;
        }
    }

    .construction-button:hover {
        button {
            background-color: #0a415d;
            color: #fff;
        }
    }

    p {
        font-size: 2em;
        text-align: center;
        font-weight: 100;
    }

    h1 {
        text-align: center;
        font-size: 10rem;
        font-weight: 100;
    }
}
