.contact-us {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .contact-us-inner-bg {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 2;
        background: url(../../../assets/bg-contactus.png) no-repeat center center fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        -webkit-animation: kenburns-top 30s ease-in-out reverse;
        animation: kenburns-top 30s ease-in-out reverse;
        filter: blur(1.5px);
        -webkit-filter: blur(1.5px);
    }
    .row.contact-us-inner {
        position: relative;
        z-index: 3;
        margin: 0px;
        width: 100%;
        height: 100%;
        padding: 50px 0px;

        .contact-us-info {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            .contact-us-info-inner {
                position: relative;
                z-index: 3;
                margin: 50px 20px 20px 20px;
                width: 60vw;
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-evenly;
                background: rgba(238, 238, 229, 0.692);
                box-shadow: 0 8px 32px 5px rgba(31, 38, 135, 0.37);
                backdrop-filter: blur(20px);
                -webkit-backdrop-filter: blur(20px);
                border-radius: 10px;
                border: 1px solid rgba(255, 255, 255, 0.18);

                .title {
                    position: relative;
                    z-index: 4;
                    top: -20px;
                    padding: 10px 20px;
                    height: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    background: rgb(238, 238, 229);
                    box-shadow: 2px 0px 20px 4px rgba(31, 38, 135, 0.37);
                    backdrop-filter: blur(20px);
                    -webkit-backdrop-filter: blur(20px);
                    border-radius: 10px;
                    border: 1px solid rgba(255, 255, 255, 0.18);

                    .title-icon {
                        color: #0a415d;
                        font-size: 2rem;
                        margin-right: 10px;
                    }
                    .title-text {
                        color: #0a415d;
                        text-transform: uppercase;
                        margin-left: 10px;
                        font-size: 1.5rem;
                    }
                }

                .btn {
                    background: #0a415d;
                    color: white;
                    margin-bottom: 2vh;
                    font-weight: bold;
                }
                .btn:hover {
                    color: #0a415d;
                    background: #fee860;
                }

                .writer-info {
                    width: 100%;
                    height: 100%;
                    .name > div:nth-child(1) > div:nth-child(1) > div:nth-child(2){
                        .MuiFormControl-root.MuiTextField-root{
                            width: 100%;
                        }
                        width: 90%;
                    }
                    .email > div:nth-child(1) > div:nth-child(1) > div:nth-child(2){
                        .MuiFormControl-root.MuiTextField-root{
                            width: 100%;
                        }
                        width: 90%;
                    }
                    .subject > div:nth-child(1) > div:nth-child(1) > div:nth-child(2){
                        .MuiFormControl-root.MuiTextField-root{
                            width: 100%;
                        }
                        width: 90%;
                    }
                    .text > div:nth-child(1) > div:nth-child(1) > div:nth-child(2){
                        .MuiFormControl-root.MuiTextField-root{
                            width: 100%;
                        }
                        width: 90%;
                    }
                    .text{
                        margin-top: 25px;
                    }
                    .info-text {
                        color: #0a415d;
                        text-transform: uppercase;
                        margin-left: 10px;
                        font-size: 1rem;
                        text-align: center;
                        margin-bottom: 20px;
                    }
                }
            }
        }
        .contact-us-map {
            margin: 50px 0px 20px 0px;
            padding: 0 30px;
            overflow: hidden;
            position: relative;
            z-index: 3;
            width: 100%;
            height: 400px;
            display: flex;
            justify-content: center;
            align-items: center;
            iframe {
                width: 100%;
                height: 100%;
            }
        }
    }
}

/**
                ANIMATIONS
 * ----------------------------------------
 * animation kenburns-top
 * ----------------------------------------
 */
@-webkit-keyframes kenburns-top {
    0% {
        filter: blur(1.5px);
        -webkit-filter: blur(1.5px);
        -webkit-transform: scale(1) translateY(0);
        transform: scale(1) translateY(0);
        -webkit-transform-origin: 50% 16%;
        transform-origin: 50% 16%;
    }
    100% {
        filter: blur(0px);
        -webkit-filter: blur(0px);
        -webkit-transform: scale(1.25) translateY(-15px);
        transform: scale(1.25) translateY(-15px);
        -webkit-transform-origin: top;
        transform-origin: top;
    }
}
@keyframes kenburns-top {
    0% {
        filter: blur(1.5px);
        -webkit-filter: blur(1.5px);
        -webkit-transform: scale(1) translateY(0);
        transform: scale(1) translateY(0);
        -webkit-transform-origin: 50% 16%;
        transform-origin: 50% 16%;
    }
    100% {
        filter: blur(0px);
        -webkit-filter: blur(0px);
        -webkit-transform: scale(1.25) translateY(-15px);
        transform: scale(1.25) translateY(-15px);
        -webkit-transform-origin: top;
        transform-origin: top;
    }
}

/* RESPONSIVE CONTACT US */
@media (max-width: 780px) {
    .contact-us-info-inner{
        width: 90vw !important;
    }
}

@media (max-width: 500px) {
    .title{
        .title-icon {
            font-size: 1.5em !important;
        }
        .title-text {
            font-size: 1rem !important;
        }
    }
    .writer-info {
        .info-text > a {
            font-size: 0.8rem;
        }
    }
}