/* Events Styles */
.events {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 75px 0 0 0;
    background-color: transparent;
    z-index: 3;
    overflow-x: hidden;

    .events-header-title {
        color: var(--events-header-title);
        font-size: 2rem;
    }
    .divisor {
        height: 3px;
        width: 90vw;
        border-radius: 50px;
        background-color: var(--bg-events-header-divior);
    }
    .events-header-figures {
        width: 100%;
        position: relative;
        z-index: 2;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .events-footer-figures {
        width: 100%;
        background-color: transparent;
        position: relative;
        z-index: 2;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
    }
    .events-body {
        width: 100vw;
        height: 100%;
        padding: 0px 3vw;
        margin-top: 20px;
        // background-color: #ffe25d;
        position: relative;
        overflow: visible;
        z-index: 1;

        .events-body-tiles {
            z-index: 3;
            position: relative;
            // top: -50px;

            .event-tile {
                padding: 5px;
                border-radius: calc(0.25rem - 1px);
                /* border: 1px solid rgba(0,0,0,.125); */
                display: grid;
                justify-content: center;
                /* background-color: #ffffff; */
                position: relative;

                .event-tile-img {
                    border-radius: calc(0.25rem - 1px);
                    overflow: hidden;
                    height: 70%;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-size: contain !important;

                    span {
                        color: #ffffff;
                        font-size: 4rem;
                    }
                    img {
                        height: auto;
                        width: 100%;
                    }
                }
                .card {
                    border: none;
                    width: 140px;
                    height: 150px;
                    border: 1px solid rgba(0, 0, 0, 0.125);
                    padding: 5px;
                    background-color: rgba(0, 0, 0, calc(5 / 100));
                    transition: all 150ms ease-out;
                    -webkit-touch-callout: none; /* iOS Safari */
                    -webkit-user-select: none; /* Safari */
                    -khtml-user-select: none; /* Konqueror HTML */
                    -moz-user-select: none; /* Old versions of Firefox */
                    -ms-user-select: none; /* Internet Explorer/Edge */
                    user-select: none;
                }
                .card:hover {
                    cursor: pointer;
                    transform: scale(1.02);
                    box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.75);
                    -webkit-box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.75);
                    -moz-box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.75);
                }
                .card-body {
                    border: none;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding: 5px 0 1px 0;

                    .event-tile-title {
                        color: #004f88;
                        font-size: 0.65rem;
                        font-weight: bold;
                        text-transform: capitalize;
                        text-align: center;
                        margin-bottom: 0px !important;
                    }
                    .event-tile-text {
                        color: #060000;
                        text-align: center;
                        font-size: 0.7rem;
                        font-weight: lighter;
                    }
                    .event-tile-btn {
                        background-color: #003864;
                        border-radius: 50px;
                        // margin-top: 0.1rem;
                        font-size: 0.6rem;
                        text-transform: uppercase;
                        font-weight: bolder;
                    }
                }
            }
        }

        .events-footer-button {
            display: flex;
            margin-bottom: 30px;
            padding-bottom: 50px;
            justify-content: center;
            position: relative;
            z-index: 3;
            cursor: pointer;

            .events-button {
                text-transform: lowercase;
                width: auto;
                height: 100%;
                font-size: 0.9rem;
                white-space: nowrap;
                border-radius: 60px;
                background-color: var(--bg-events-button);
                margin-top: 20px;
                // color: #fee860;
                color: white;
                transition: all 0.3s ease;
                text-align: center;
                padding: 10px 15px;
               
            }
            .events-button:hover {
                // font-size: 1rem;
                box-shadow: 0px 0px 6px -1px rgba(0, 0, 0, 0.75);
                -webkit-box-shadow: 0px 0px 6px -1px rgba(0, 0, 0, 0.75);
                -moz-box-shadow: 0px 0px 6px -1px rgba(0, 0, 0, 0.75);
            }
            .lines {
                position: absolute;
                left: 0;
                margin-left: -2.5vw;
            }
        }
    }
}

@media (max-width: 500px) {
    .titles {
        font-size: 2rem !important;
    }
    .events-button {
        width: 45vw !important;
    }
    .lines {
        display: none !important;
    }
}

@media (max-width: 768px) {
    .events-header-figures {
        display: none !important;
    }
}
