/* ABOUTUS LINKS STYLES */

.aboutus-links {
    width: 100%;
    height: 100%;
    margin: 4rem 0px;
    .aboutus-links-row {
        margin: 0px;
        width: 100%;
        padding: 0px 10vw;
        // .aboutus-link:hover {
        //     cursor: pointer;
        //     -webkit-box-shadow: -1px 1px #0a415d, -2px 2px #0a415d, -3px 3px #0a415d, -4px 4px #0a415d, -5px 5px #0a415d,
        //         -6px 6px #0a415d, -7px 7px #0a415d, -8px 8px #0a415d;
        //     box-shadow: -1px 1px #0a415d, -2px 2px #0a415d, -3px 3px #0a415d, -4px 4px #0a415d, -5px 5px #0a415d,
        //         -6px 6px #0a415d, -7px 7px #0a415d, -8px 8px #0a415d;
        //     -webkit-transform: translateX(8px) translateY(-8px);
        //     transform: translateX(8px) translateY(-8px);
        //     background: #fee860;
        // }
        .aboutus-link:hover {
            cursor: pointer;
            // -webkit-box-shadow: -1px 1px #0a415d, -2px 2px #0a415d, -3px 3px #0a415d, -4px 4px #0a415d, -5px 5px #0a415d,
            //     -6px 6px #0a415d, -7px 7px #0a415d, -8px 8px #0a415d;
            // box-shadow: -1px 1px #0a415d, -2px 2px #0a415d, -3px 3px #0a415d, -4px 4px #0a415d, -5px 5px #0a415d,
            //     -6px 6px #0a415d, -7px 7px #0a415d, -8px 8px #0a415d;
            // -webkit-transform: translateX(8px) translateY(-8px);
            // transform: translateX(8px) translateY(-8px);
            background: #fee860;

            .link-text {
                color: #0a415d;
            }
            
          .icon-svg{
                // color:#fee860;
                // background-color: #0a415d;
                filter: invert(1) sepia(1) saturate(5) hue-rotate(175deg);
            }
        }
        .aboutus-link {
            background: #0a415d  ;
            padding: 25px 30px;
            margin: 15px 15px;
            transition: all 0.3s ease-out;
            justify-content: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            // -webkit-animation: 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) both;
            // animation: 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) both;
            // animation-play-state: paused;
            // -webkit-box-shadow: 0 0 #0a415d, 0 0 #0a415d, 0 0 #0a415d, 0 0 #0a415d, 0 0 #0a415d, 0 0 #0a415d,
            //     0 0 #0a415d, 0 0 #0a415d;
            // box-shadow: 0 0 #0a415d, 0 0 #0a415d, 0 0 #0a415d, 0 0 #0a415d, 0 0 #0a415d, 0 0 #0a415d, 0 0 #0a415d,
            //     0 0 #0a415d;
            // -webkit-transform: translateX(0) translateY(0);
            // transform: translateX(0) translateY(0);
            .link-icon {
                width: 55px;
                height: 55px;
                // border-radius: 50%;
                // background-color: #fee860;
                // color: #0a415d;
                // display: flex;
                // justify-content: center;
                // align-items: center;
                // margin: 5px;
                // font-size: 1.5rem;
            }
            .link-text {
                font-family: sans-serif;
                font-weight: 700;
                color: white;
                margin: 5px;
                text-align: center;
            }
            .icon-svg {
                width: 100%;
            }
        }
    }
}
