/* FERIA CULTURAL STYLES */

.feriaCultural {
    width: 100%;
    height: 100%;
    margin-top: 50px;
    .feriaCultural-inner {
        margin-top: 30px;
        padding: 0px 15vw;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
       

        .feriaCultural-title {
            margin: 20px 0px;
            font-size: 1.2rem;
            font-weight: bold;
            text-transform: uppercase;
            color: #0a415d;
        }
        .feriaCultural-text {
            p {
                color: #0a415d;
                font-weight: 300;
                font-size: 1rem;
                text-align: justify;
                white-space: pre-line;
            }
        }
    }
}

@media (max-width: 780px) {
    .feriaCultural-inner {
        padding: 0px 6vw !important;
    }
}