/* COURSE TILE STYLES */

.course-tile {
    width: 100%;
    height: 100%;
    .course-tile-inner {
        // width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .tile {
            background-color: #0a415d;
            height: 70px;
            width: 70px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            font-size: 20px;
            margin-bottom: 20px;
            cursor: pointer;
        }
    }
    .hvr-outline-out {
        display: inline-block;
        vertical-align: middle;
        -webkit-transform: perspective(1px) translateZ(0);
        transform: perspective(1px) translateZ(0);
        position: relative;
    }
    .hvr-outline-out:before {
        content: "";
        position: absolute;
        border: #0a415d solid 4px;
        top: 2px;
        right: 2px;
        bottom: 2px;
        left: 2px;
        border-radius: 50%;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-property: top, right, bottom, left;
        transition-property: top, right, bottom, left;
    }
    .hvr-outline-out:hover:before{
        top: -8px;
        right: -8px;
        bottom: -8px;
        left: -8px;
    }
}

/* CROUSE TILE RESPONSIVE */
// @media(max-width: 1200px) {
//     .philosophy-inner {
//         padding: 0px 5vw !important;
//     }
// }
