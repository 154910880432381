/* CURRICULUM STYLES */

.row.curriculum-body {
    margin-left: 0px;
    margin-right: 0px;
}
.curriculum-body {
    width: 100%;
    height: 100%;
}
.curriculum {
    width: 100%;
    height: 100%;
    margin-bottom: 150px;
   
    .curriculum-inner {
        margin-top: 30px;
        padding: 0 15vw;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #0a415d;
        .curriculum-title, .subject-title {
            // text-transform: uppercase;
            margin: 20px 0px;
            font-size: 1.2rem;
            font-weight: bold;
            align-self: flex-start;
        }
        .curriculum-subtitle {
            margin-top: 20px;
            font-size: 1.2rem;
            font-weight: bold;
        }
        .curriculum-text {
            margin-bottom: 5vh;
            p {
                font-weight: 300;
                font-size: 1rem;
                text-align: justify;
                white-space: pre-line;
            }
        }

        .curriculum-subjects {
            width: 100%;
            height: 100%;
            transition: all ease 0s;
            .curriculum-subjects-inner {
                width: 100%;
                height: 100%;
                transition: all ease 0s;
                .subjets-boxes {
                    margin: 30px 0px;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    flex-wrap: wrap;
                    transition: all ease 0s;
                    .subject {
                        height: 60px;
                        width: 160px;
                        padding: 5px;
                        margin: 10px;
                        color: white;
                        background-color: #153a4d;
                        cursor: pointer;
                        .subject-inner {
                            width: 100%;
                            height: 100%;
                            font-size: 0.8rem;
                            font-weight: 400;
                            // text-transform: uppercase;
                            white-space: pre-wrap;
                            text-align: center;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }
                }
            }
        }
    }
    .curriculum-division {
        margin: 30px 0px;
        width: 100%;
        height: 100%;
        .row {
            width: 100%;
            margin: 0;
        }
        .subjects-inner,
        .evaluation-inner {
            width: 100%;
            height: 100%;
            background-color: #0a415d;
            // text-transform: uppercase;
            white-space: pre-wrap;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            transition: all 0.4s ease;
            text-align: center;
        }
        .subjects-inner:hover,
        .evaluation-inner:hover {
            background-color: rgba(10, 65, 93, 0.8);
        }
        .subjects,
        .evaluation {
            width: 100%;
            height: 70px;
            color: white;
            padding: 15px 40px;
            font-size: 1.2rem;
            font-weight: 100;
        }
        .subjects,
        .evaluation {
            padding: 10px;
        }
    }
}
.react-multi-carousel-dot-list {
    .custom-dot {
        position: relative;
        // transform: scale(1);
        transition: all 300ms ease;
    }
    .custom-dot:hover {
        cursor: pointer;
        transform: scale(1.03);
        box-shadow: 0px 0px 10px -1px rgba(0, 0, 0, 0.75);
        -webkit-box-shadow: 0px 0px 10px -1px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 0px 0px 10px -1px rgba(0, 0, 0, 0.75);
    }
}

.row-sub {
    
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    
     .subjectTile {
         display: flex;
         transition: all 0.3s ease-in-out;
         // background-color: #0a415d;
         background-color: #ffe25d;
         padding: 5px 20px;
         margin: 10px;
         cursor: pointer;
     
         .subjectTile-title {
            align-self: center;
             color: #0a415d;
             span {font-weight: bolder;}
             
         }
        
         transition: all 0.3s ease;
    
       
     }
     .active {
         background-color: #0a415d;
         .subjectTile-title {
             color: white;
             p {font-weight: bolder;}
             
         }
     }
     .subjectTile:hover {
         background-color: #0a415d;
         .subjectTile-title {
             color: white;
             p {font-weight: bolder;}
             
         }
     }
 }
 .personal-row{
    flex-direction: row;
    padding: 5vh 15vw;
    color: #0a415d;
    .personal-title {
        margin: 20px 0px;
        font-size: 1.2rem;
        font-weight: bold;
    }
    .personal-text {
        font-weight: 300;
        font-size: 1rem;
        text-align: justify;
        white-space: pre-line;
     }
 }


/* CURRICULUM RESPONSIVE */
@media (max-width: 1200px) {
    .curriculum-inner {
        padding: 0px 5vw !important;
    }
    .personal-row{
        padding: 0px 5vw !important;
    }
    // .row-sub {
    //     grid-template-columns: minmax(0,1fr);
    // }
}

@media (max-width: 700px) {
   
    .row-sub {
        grid-template-columns: minmax(0,1fr) minmax(0,1fr);
    }
}

@media (max-width: 400px) {
   
    .row-sub {
        grid-template-columns: minmax(0,1fr);
    }
}
