/* Submenues styles */

.submenu {
    width: 100%;
    height: var(--header-submenu-2);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    .exit-link {
        height: 100%;
    }
    .exit-arrow {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.3rem;
        color: #0a415d;
        height: 100%;
        padding: 10px 20px 10px 20px;
        -webkit-transition: all 1s ease; /* Safari and Chrome */
        -moz-transition: all 1s ease; /* Firefox */
        -o-transition: all 1s ease; /* IE 9 */
        -ms-transition: all 1s ease; /* Opera */
        transition: all 1s ease;
    }
    .exit-link:hover {
        -webkit-animation: jello-horizontal 0.5s both;
        animation: jello-horizontal 0.5s both;
    }
    .links-group {
        position: relative;
        height: 75%;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        flex-wrap: wrap;

        .links {
            z-index: 9;
            position: relative;
            padding: 5px 10px;
            margin: 0 5px;
            overflow: hidden;
            h6 {
                font-size: 0.8rem !important;
                transition: all 0.3s ease;
                overflow: hidden;
                &::after {
                    z-index: 10;
                    position: absolute;
                    transition: all 0.3s ease;
                    content: "";
                    width: 100%;
                    left: -100%;
                    bottom: 0;
                    height: 2px;
                    background: #fee860;
                }
                &:hover {
                    cursor: pointer;
                    &::after {
                        left: 0;
                    }
                }
            }
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            transition: 0.5s;
            color: var(--text-color-submenu);
            white-space: pre-wrap;
            text-align: center;
            text-transform: uppercase;
            transition: all 1s ease-in 0s;
        }

        // .links:hover {
        //     a:nth-child(1) {
        //         // font-family: "AleoBold";
        //         color: #373737 !important;
        //     }
        // }
    }
    .border-left {
        width: 2px;
        height: 12px;
        background-color: #0a415d;
    }
}
.submenu-curriculum {
    .links-group {
        justify-content: space-evenly;
    }
}

/* SUBMENU EXIT BUTTON EFFECTS */
@-webkit-keyframes jello-horizontal {
    0% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
    30% {
        -webkit-transform: scale3d(1.25, 0.75, 1);
        transform: scale3d(1.25, 0.75, 1);
    }
    40% {
        -webkit-transform: scale3d(0.75, 1.25, 1);
        transform: scale3d(0.75, 1.25, 1);
    }
    50% {
        -webkit-transform: scale3d(1.15, 0.85, 1);
        transform: scale3d(1.15, 0.85, 1);
    }
    65% {
        -webkit-transform: scale3d(0.95, 1.05, 1);
        transform: scale3d(0.95, 1.05, 1);
    }
    75% {
        -webkit-transform: scale3d(1.05, 0.95, 1);
        transform: scale3d(1.05, 0.95, 1);
    }
    100% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}
@keyframes jello-horizontal {
    0% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
    30% {
        -webkit-transform: scale3d(1.25, 0.75, 1);
        transform: scale3d(1.25, 0.75, 1);
    }
    40% {
        -webkit-transform: scale3d(0.75, 1.25, 1);
        transform: scale3d(0.75, 1.25, 1);
    }
    50% {
        -webkit-transform: scale3d(1.15, 0.85, 1);
        transform: scale3d(1.15, 0.85, 1);
    }
    65% {
        -webkit-transform: scale3d(0.95, 1.05, 1);
        transform: scale3d(0.95, 1.05, 1);
    }
    75% {
        -webkit-transform: scale3d(1.05, 0.95, 1);
        transform: scale3d(1.05, 0.95, 1);
    }
    100% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}
