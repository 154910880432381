.students-page{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.students-container {
    width: 100%;
    .students-title {
        text-transform: uppercase;
        margin: 20px 0px;
        font-size: 1.2rem;
        font-weight: bold;
        margin-top: 60px;
        color: #0a415d;
        padding-left: 5vw;
    }
    .students-book {
        display: flex;
        justify-content: center;
    }
}
.students-container:last-child {
    transition: opacity 300ms, transform 300ms;
}

.container {
    display: grid;
    gap: 20px 20px;
   grid-template-columns: repeat(3,  minmax(0, 1fr));
    margin:50px auto;
    cursor: pointer;
    
    .item{
        width: 100%;
        height: 200px;
        transition: all 0.3s ease;
        // background: url("https://scontent.fsdq1-2.fna.fbcdn.net/v/t1.18169-9/10462828_1436643863272130_8928361970710478748_n.png?_nc_cat=109&ccb=1-5&_nc_sid=973b4a&_nc_ohc=a8nXI98WqpsAX9ztakk&_nc_ht=scontent.fsdq1-2.fna&oh=f7a3449c083692fc31d6cea437194847&oe=61C6F870");
        // background-position: ;
        // object-fit: contain;
        margin-right: 20px;
        position: relative;
       
        .image-bg {
            height: 100%; width: 100%;
            object-fit: cover;
        }
        .content {
            position: absolute;
            bottom: 0;
            left: 0;
            top: 70%;
            width: 100%;
            background: rgba(255, 255, 255, 0.5);
        
        backdrop-filter: blur(8.5px);
        -webkit-backdrop-filter: blur(8.5px);

            .text-content {
                display: flex;
                align-items: center;
                color: #0a415d;
            }

        
        }
    }

    .item:hover {
        box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    }
}

.menu-section {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 20px 20px;
    transition: opacity 300ms, transform 300ms;
    padding-top: 20px;
    .menu-item {
        background-color: #fee860;
        color: #0a415d;
        cursor: pointer;
        height: 20vh;
        width: 230px;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        transition: all 0.3s ease-out;
    }
    .menu-item__active {
        background-color: #0a415d;
        .menu-content {
            filter: brightness(0) invert(99%) sepia(67%) saturate(1604%) hue-rotate(
            327deg) brightness(104%) contrast(88%) !important;
        }
    }
}

.menu-item:hover {
        background-color: #0a415d;
        .menu-content {
            filter: brightness(0) invert(99%) sepia(67%) saturate(1604%) hue-rotate(
            327deg) brightness(104%) contrast(88%) !important;
}
        }

.text-content:first-child {
    margin: 10px;
}

.container:last-child {

    margin-left: 0px;
}

@media (max-width: 960px) {
   
    .container {
       grid-template-columns: repeat(2,  minmax(0, 1fr));
    }
    .menu-section  {
        grid-template-columns:  repeat(2,  minmax(0, 1fr));
        
     }
}

@media (max-width: 700px) {
   
    .container {
       grid-template-columns:  minmax(0, 1fr);
    }
    .menu-section  {
        grid-template-columns:  minmax(0, 1fr);
        
     }
}

@media (max-width: 400px) {
   
    .container {
       grid-template-columns:  minmax(0, 1fr);
    }
    // .menu-section  {
    //     grid-template-columns:  minmax(0, 1fr);
        
    //  }
}

.subpage {
    .students-board-title,
    .students-board-text {
        display: none;
    }
}

.menu-container {
    margin: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    .menu-image > img {
       height: 100vh;
       object-fit: contain;
       transition: opacity 300ms, transform 300ms;
    }
}

.areas-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    .areas-title {
        text-transform: uppercase;
        margin: 20px 0px;
        font-size: 1.2rem;
        font-weight: bold;
        margin-top: 60px;
        color: #0a415d;
    }
}


 // areas transotion styles
 .areas-container-enter {
    opacity: 0;
    // transform: scale(0.9);
  }
  .areas-container-exit {
    opacity: 1;
  }
  .areas-container-enter-active {
    opacity: 1;
    // transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
  }
  
  .areas-container-exit-active {
    opacity: 0;
    // transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
  }
  

// menu caf transotion styles
.menu-container-enter {
    opacity: 0;
    // transform: scale(0.9);
  }
  .menu-container-exit {
    opacity: 1;
  }
  .menu-container-enter-active {
    opacity: 1;
    // transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
  }
  
  .menu-container-exit-active {
    opacity: 0;
    // transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
  }

// students transotion styles
.students-container-enter {
    opacity: 0;
    // transform: scale(0.9);
  }
  .students-container-exit {
    opacity: 1;
  }
  .students-container-enter-active {
    opacity: 1;
    // transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
  }
  
  .students-container-exit-active {
    opacity: 0;
    // transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
  }

 