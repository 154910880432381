/* HISTORY STYLES */

.history {
    width: 100%;
    height: 100%;
    .history-inner {
        margin-top: 30px;
        padding: 0px 5vw;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .history-title {
            margin: 20px 0px;
            font-size: 1.2rem;
            font-weight: bold;
            text-transform: uppercase;
            align-self: flex-start;
            color: #0a415d;
        }
        .history-text {
            p {
                color: #0a415d;
                font-weight: 300;
                font-size: 1rem;
                text-align: justify;
                white-space: pre-line;
            }
        }
    }
}
