/*
    Style sheet in SASS/LESS for defaults and globals configurations.
*/

/* Google fonts & incos */
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Odibee+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swa');
@import url('https://fonts.googleapis.com/css2?family=Odibee+Sans&display=swap');
@font-face {
    font-family: "AleoBold";
    src: local("AleoBold"),url("./assets/fonts/gothamRnd/GothamRounded/GothamRnd-Bold.otf") format("truetype");
}
@font-face {
    font-family: "AleoRegular";
    src: local("AleoRegular"),url("./assets/fonts/gothamRnd/GothamRounded/GothamRnd-Medium.otf") format("truetype");
}
@font-face {
    font-family: "AleoLight";
    src: local("AleoLight"),url("./assets/fonts/gothamRnd/GothamRounded/GothamRnd-Light.otf") format("truetype");
}


// ORIGINAL FONTS
// @font-face {
//     font-family: "AleoBold";
//     src: local("AleoBold"),url("./assets/fonts/Aleo-Bold.otf") format("truetype");
//     font-weight: bold;
// }
// @font-face {
//     font-family: "AleoRegular";
//     src: local("AleoRegular"),url("./assets/fonts/Aleo-Regular.otf") format("truetype");
//     font-weight: normal;
// }
// @font-face {
//     font-family: "AleoLight";
//     src: local("AleoLight"),url("./assets/fonts/Aleo-Light.otf") format("truetype");
//     font-weight: 500; //Ligth
// }


/* General Styles */
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline-style: none;
    text-decoration: none;
    --loader-bar-color:#0a415d;
}
::after,::before{
    box-sizing: border-box;
}
body{
    font-family: 'AleoLight';
    font-style: normal;
    font-size: 14px;
    font-weight: 800;
}

// Main constants

$header_submenu_1: 7vh;
$header_submenu_2: 18vh;

:root{
   
    --bg: #ffffff;
    --bg-transparent: rgba(0, 0, 0, 0);
    --bg-header: #82d851;
    --bg-main: #7a1f1f;
    --bg-sidebar: #0c192b;
    --bg-footer: #0a415d;
    --bg-accent: #484a4d;
    --bg-icon: #0042a3;
    --bg-border: #000000;
    --border: 2vh solid #2d2d2d;
    --icon-color: #ffffff;
    --icon-color-header: #0a415d;
    --bg-icon-color: #0a415d;
    --user-icon-color: #0042a3;
    --text-color: #dadce1;
    --text-color-submenu: #1b4e67;
    --text-color-footer: #ffffff;

    --header-submenu-1: #{$header_submenu_1};
    --header-submenu-2: #{$header_submenu_2};
    --main-slider-margin-top: calc(#{$header_submenu_1} + #{$header_submenu_2});
    --main-slider: calc(94vh - (#{$header_submenu_1} + #{$header_submenu_2}));

    --slider-height-percentage-n: 60%;
    --organic-arrow-color-n: #ffe25d;
    --organic-arrow-thickness-n: 6px;
    --organic-arrow-height-n: 40px;
    --organic-arrow-border-radius-n: 50px;
    --control-button-width-n: 10%;
    --control-button-height-n: 25%;
    --control-button-opacity-n: 0.5;

    --triangle-color: #0a415d;
    --triangle-color-inner: #ffffff;
    --triangle-color-outer: #0a415d;
    
    --cicle-rectangle-color: #ffe25d;
    --cicle-rectangle-divisor-color: #ffffff;

    --cocurriculars-header: #003864;
    --cocurriculars-card: #004f88;
    --bg-cocurriculars-button: #003864;

    --events-header-title: #003864;
    --bg-events-header-divior: #0a415d;
    --bg-events-button: #003864;

    --bg-dots: #0a415d;

    --bg-news: #0a415d;
    --news-text: #0a415d;
    --news-header-title: #ffffff;

    --bg-main-divisor: #0a415d;
    --nav-size: 60px;
    --border-radius: 5px;
    --speed: 0.5s;

   

}

.container-fluid {
    a,
    a:hover,
    a:active,
    a:visited {
        text-decoration: none;
            color: inherit;
            width: 100%;
            height: 100%;
            display: contents;
    }
}

h1,h2,h3,h4,h5,h6{
    margin-bottom: 0;
}

.MuiGrid-spacing-xs-4 > .MuiGrid-item{
    padding: 0;
    margin: 16px;
}
.MuiGrid-container{    
    width: auto;
    margin: 0;
}

// "development": [
//     "last 1 chrome version",
//     "last 1 firefox version",
//     "last 1 safari version"
// ]   