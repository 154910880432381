/* DIALOG STYLES */

.dialog-header {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .dialog-titulo {
        text-transform: uppercase;
    }
    .dialog-fecha {
        font-style: italic;
    }
}

.profile-dialog-header {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    .profile-dialog-image {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 10px;
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: top;
        }
    }
    .profile-dialog-image-info{
        width: auto;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        .profile-dialog-name {
            text-transform: capitalize;
        }
        .profile-dialog-position{
            font-weight: 300;
            font-style: italic;
            font-size: 14px;
            text-transform: capitalize;
        }
    }

}
.profile-dialog-body {
    margin: 0 0 30px 0;
    .info {
        padding: 5px 0px;
    }
    .profile-dialog-body-nacionalidad {
        width: 100%;
        height: 100%;
    }
    .profile-dialog-body-profesion {
        width: 100%;
        height: 100%;
    }
    .profile-dialog-body-correo {
        width: 100%;
        height: 100%;
    }
}
.profile-dialog-frase {
    width: 100%;
    height: 100%;
}
.virtualtool-dialog-image{
    display: flex;
    justify-content: center;
    align-items: center;
    img{
        height: auto;
        width: 100%;
    }
}
.virtualtool-dialog-image-moodle{
    img{
        height: auto;
        width: 80%;
    }
}
