/* Header Styles */

.header-wrapper {
    width: 100%;
    margin: 0;
    padding: 0px 0px;

    .row.header {
        position: fixed;
        z-index: 10;
        top: 0;
        width: 100%;
        padding: 0px 0px;

        height: var(--header-height);
    }
    .header-navbar {
        width: 100%;
        margin: 0;
        padding: 0px 0px;
    }
    .header-inner {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        // border-bottom: 2px solid #0a415d;
        // background-color: var(--bg-transparent);
        // GLASSMORPHISM
        background: rgba(255, 255, 255, 0.9);
        box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
        backdrop-filter: blur(8.5px);
        -webkit-backdrop-filter: blur(8.5px);

        .logo {
            padding: 5px 5px 5px 15px;
            flex-basis: 20%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            img {
                width: auto;
                height: 90px;
                // fill: #0a415d;
            }
            .logo-text {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                margin-top: 10px;
                color: var(--text-color-submenu);
                text-transform: uppercase;
                font-size: 10px;
                white-space: nowrap;
            }
        }
        .divisor-header {
            width: 2.5px;
            height: 100%;
            background-color: #0a415d;
        }
        .menu {
            height: 100%;
            width: 100%;

            .sub-menu-inner {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-evenly;
                overflow: hidden;

                h5,
                h6 {
                    font-family: "AleoRegular";
                    font-size: 14px;
                }

                .sub-menu-1 {
                    width: 100%;
                    padding: 0px 4vw;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    // border-bottom: 2px solid #0a415d;
                    flex-basis: 50%;
                    background-color: #fee860;
                    // background-color: #0a415d;

                    .iconColorGroup {
                        // filter: brightness(0) saturate(100%) invert(19%) sepia(14%) saturate(5890%) hue-rotate(
                        //     172deg) brightness(79%) contrast(92%);

                           
                        //         filter: brightness(0) invert(99%) sepia(67%) saturate(1604%) hue-rotate(
                        //     327deg) brightness(104%) contrast(88%);
                    }
                    .links:hover{
                      box-shadow: 0px -1px 19px -1px rgba(0, 0, 0, 0.75);
                      -webkit-box-shadow: 0px -1px 19px -1px rgba(0, 0, 0, 0.75);
                      -moz-box-shadow: 0px -1px 19px -1px rgba(0, 0, 0, 0.75);
                    //   border: transparent solid 2px;
                      cursor: pointer;
                      transition: all 300ms ease-in-out;
                    }
                    .links {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        padding: 0pc 5px;
                        transition: all 0.2s ease-out;
                        // border: #0a415d solid 2px;
                        padding: .5rem;
                        margin: .5rem;
                        border-radius: 50px;
                        i {
                            padding: 5px;
                        }
                        img {
                            padding: 0px 5px;
                            height: 20px;
                            // color: #0a415d;
                            // filter: invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg) brightness(0%)
                            //     contrast(100%);
                        }
                        h6 {
                            font-size: 0.7rem;
                        }
                       
                    }
                    .border-left {
                        width: 3px;
                        height: 15px;
                        background-color: #0a415d;
                    }
                    .sub-menu-1-socials {
                        height: var(--header-submenu-1);
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        /* Socials Styles */
                        .socials-inner {
                            position: relative;
                            width: 100%;
                            height: 240px;
                            display: flex;
                            flex-direction: row;
                            justify-content: center;
                            align-items: center;
                        }
                        .socials-icons {
                            width: 100%;
                            z-index: 3;
                            display: flex;
                            flex-direction: row;
                            justify-content: center;
                            align-items: center;
                        }
                        .social-icon {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            font-size: 20px;
                            font-weight: bolder;
                            margin: 0px 5px;
                            width: 30px;
                            height: 30px;
                            border-radius: 50%;
                            // background-color: #fee860;
                            transition: all 0.1s ease-in;
                        }
                        .social-icon:hover {
                            transform: scale(0.95);
                            box-shadow: 0px -1px 19px -1px rgba(0, 0, 0, 0.75);
                            -webkit-box-shadow: 0px -1px 19px -1px rgba(0, 0, 0, 0.75);
                            -moz-box-shadow: 0px -1px 19px -1px rgba(0, 0, 0, 0.75);
                        }
                        .socials-dots-array {
                            position: absolute;
                            z-index: 3;
                            width: 100%;
                            justify-content: center;
                            align-items: flex-start;
                            .social-dots-array {
                                margin-left: 20px;
                            }
                        }

                        /* FontAwesome icons colors */
                        .fab {
                            color: var(--icon-color-header);
                            // color: #fee860;
                        }
                    }
                    .sub-menu-1-accounts {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        .links {
                            a:nth-child(1) {
                                align-items: flex-end;
                            }
                        }
                    }
                }
                transition-property: all; 
                    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
                    transition-duration: 600ms; 
                .sub-menu-2 {
                    position: sticky;
                    width: 100%;
                    height: 100%;
                    padding: 0px 25px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-basis: 50%;
                    flex-wrap: nowrap;

                    
                    &.hide {
                       display: none;
                    }
                }
                
            }
        }
    }
    .header-inner-blurred {
        position: absolute;
        width: 100%;
        top: 0vh;
        right: 0;
        left: 0;
        bottom: 0;
        opacity: 0.9;
        background-color: rgba(255, 255, 255, 0.9);
        z-index: -1;
    }
    div.links > a:nth-child(1) {
        z-index: 9;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        color: var(--text-color-submenu);
    }
}

// --------------- MAIN SUBMENU EFFECT --------------------
.sub-menu-main-enter {
    opacity: 0;
    transform: translateY(100%);
}
.sub-menu-main-enter-active {
    opacity: 1;
    transform: translateY(0%);
    transition: opacity 0.5s, transform 0.5s ease-out 0s;
}
.sub-menu-main-exit {
    opacity: 1;
    transform: translateY(0);
}
.sub-menu-main-exit-active {
    opacity: 0;
    transform: translateY(100%);
    transition: opacity 0.5s, transform 0.5s ease-in 0s;
}

// --------------- ABOUT US SUBMENU EFFECT --------------------
.sub-menu-aboutus-enter {
    opacity: 0;
    transform: translateY(100%);
}
.sub-menu-aboutus-enter-active {
    opacity: 1;
    transform: translateX(0%);
    transition: opacity 0.5s, transform 0.5s ease-out 0s;
}
.sub-menu-aboutus-exit {
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 0.5s, transform 0.5s ease-in 0s;
}
.sub-menu-aboutus-exit-active {
    opacity: 0;
    transform: translateX(100%);
}

// --------------- CURRICULUM SUBMENU EFFECT --------------------
.sub-menu-curriculum-enter {
    opacity: 0;
    transform: translateY(100%);
}
.sub-menu-curriculum-enter-active {
    opacity: 1;
    transform: translateX(0%);
    transition: opacity 0.5s, transform 0.5s ease-out 0s;
}
.sub-menu-curriculum-exit {
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 0.5s, transform 0.5s ease-in 0s;
}
.sub-menu-curriculum-exit-active {
    opacity: 0;
    transform: translateX(100%);
}

/* RESPONSIVE HEADER */
@media (max-width: 780px) {
    .row.header {
        display: none;
    }
   
    .awssld__controls button {
        top: calc(45% - (0.5 * var(--control-button-height))) !important;
    }
    .subpage {
        margin-top: 0px !important;
    }
}
@media (max-width: 500px) {
    .socials-dots-array {
        display: none;
    }
}
@media (max-width: 400px) {
    h1.navbar-title {
        font-size: 12px !important;
        font-weight: bold;
    }
    p.navbar-motto {
        font-size: 9px !important;
        font-weight: lighter;
    }
}

@media (min-width: 780px) {
    .header-navbar {
        display: none;
    }
    .awssld__controls button {
        top: calc(60% - (0.5 * var(--control-button-height))) !important;
    }
}
