// /* Modify slider's arrows style */
.aboutus-slider {
    height: 100%;
    width: 100%;

    padding: 0px 0px;
    .awssld__controls button {
        z-index: 1;
    }
    .awssld {
        height: 45vh;
    }
    .awssld::after {
        position: absolute;
        content: "";
        background: rgba(0, 0, 0, 0);
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 8;
        // margin: 0 auto;
    }
    .slider-overlay {
        position: relative;
        display: block;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }
    .awssld__content > img,
    .awssld__content > video {
        object-fit: cover;
        object-position: 0px -100px;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
}
