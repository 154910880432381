// /* Modify slider's arrows style */
.curriculum-slider {
    height: 100%;
    width: 100%;
    
    .awssld__controls button {
        z-index: 1;
    }
    .awssld {
        height: 45vh;
    }
    .awssld::after {
        position: absolute;
        content: "";
        // background: rgba(0, 0, 0, 0.3);
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 8;
        // margin: 0 auto;
    }
    .awssld__bullets button{
        z-index: 9;
        width: 10px;
        height: 10px;
        opacity: 0.2;
        margin: 3px;
    }
    .slider-overlay {
        position: relative;
        display: block;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }
}
