.dropdown{
    width: 100%;
}/* Dropdown Styles */


.chooser-item {
    display: flex;
    flex-direction: column;
    
    
    transition: all 0.3s ease-in;
    .fa-sort-down, .fa-sort-up{
        margin-left: 10px;
        align-self: center;
    }
    

    .item-label{
        
        display: flex;
        min-width: 300px;
        justify-content: space-between;
        cursor: pointer;
        text-transform: uppercase;
        color: #0a415d;
        text-transform: uppercase;
        color: #0a415d;
        border-bottom: 2px solid #0a415d;
        margin-bottom: 2vh;
    }
                                                                                                 

    .item-text {
       p { color: #0a415d;
        font-weight: 300;
        font-size: 1rem;
        text-align: justify;}
        transition: all 0.3s ease-in;
        margin-right: 2.2rem
    }
}