/* ABOUTUS LINKS STYLES */

.activities-links {
    width: auto;
    height: 100%;
    margin: 2vh 0 0 0;
    .activities-links-row {
        margin: 0px;
        width: 100%;
        padding: 0px 15vw;
        justify-content: center;
        .activities-link:hover {
            cursor: pointer;
            background-color: #fee860;
            .link-text {
                color: #0a415d;
            }
            // -webkit-box-shadow: -1px 1px #0a415d, -2px 2px #0a415d, -3px 3px #0a415d, -4px 4px #0a415d, -5px 5px #0a415d,
            //     -6px 6px #0a415d, -7px 7px #0a415d, -8px 8px #0a415d;
            // box-shadow: -1px 1px #0a415d, -2px 2px #0a415d, -3px 3px #0a415d, -4px 4px #0a415d, -5px 5px #0a415d,
            //     -6px 6px #0a415d, -7px 7px #0a415d, -8px 8px #0a415d;
            // -webkit-transform: translateX(8px) translateY(-8px);
            // transform: translateX(8px) translateY(-8px);
            .icon-svg{
                filter: invert(1) sepia(1) saturate(5) hue-rotate(175deg);
            }
        }
        .activities-link {
            background: #0a415d  ;
            padding: 60px 60px;
            margin: 10px 10px;
            transition: all 0.3s ease-out;
            justify-content: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 22vh;
            // -webkit-animation: 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) both;
            // animation: 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) both;
            // animation-play-state: paused;
            // -webkit-box-shadow: 0 0 #0a415d, 0 0 #0a415d, 0 0 #0a415d, 0 0 #0a415d, 0 0 #0a415d, 0 0 #0a415d,
            //     0 0 #0a415d, 0 0 #0a415d;
            // box-shadow: 0 0 #0a415d, 0 0 #0a415d, 0 0 #0a415d, 0 0 #0a415d, 0 0 #0a415d, 0 0 #0a415d, 0 0 #0a415d,
            //     0 0 #0a415d;
            // -webkit-transform: translateX(0) translateY(0);
            // transform: translateX(0) translateY(0);
            .link-icon {
                width: 55px;
                height: 55px;
                // border-radius: 50%;
                // background-color: #0a415d;
                // color: white;
                // display: flex;
                // justify-content: center;
                // align-items: center;
                // margin: 5px;
                // font-size: 1.5rem;
            }
            .link-text {
                font-family: sans-serif;
                font-weight: 700;
                margin: 5px;
                color: white;
                text-align: center;
                text-transform: uppercase;
            }
            .icon-svg {
                width: 100%;
            }
        }
    }
}


@media (max-width: 780px) {
    .activities-links-row  {
        padding: 0px 6vw !important;
    }
}