/* DEPARTMENTS STYLES */

.departments {
    width: 100%;
    height: 100%;
    .mission-vision.row {
        width: 100%;
    }
    .departments-inner {
        margin-top: 30px;
        margin-bottom: 15vh;
        padding: 0px 15vw;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        // justify-content: center;
        align-items: center;

        .departments-title {
            // text-transform: uppercase;
            // margin: 20px 0px;
            // font-size: 1.2rem;
            // font-weight: bold;
            align-self: flex-start;
            text-transform: uppercase;
            margin: 20px 0px;
            font-size: 1.2rem;
            font-weight: bold;
            margin-top: 40px;
            color: #0a415d;
        }
        .departments-subtitle {
            margin-top: 20px;
            font-size: 1.2rem;
            font-weight: bold;
        }
        .departments-text {
            font-weight: lighter;
            font-size: 1rem;
            text-align: justify;
        }

        .areas {
            width: 100%;
            height: 100%;
            padding: 0px 0px;
            margin-top: 15px;
            position: relative;
            z-index: 8;
            .row {
                width: 100%;
                margin: 0;
                justify-content: center;
            }
            .col-card {
                width: 100%;
                height: 100%;
                display: flex;
                padding: 10px 5px;
                justify-content: center;
                align-items: center;
            }
            .area-title {
                position: absolute;
                background-color: white;
                padding: 0px 10px;
                top: 0%;
                left: 50%;
                transform: translate(-50%, -50%);
                text-transform: uppercase;
            }
            .area-card-container {
                display: inline-block;
                margin: 0 auto;
                padding: 0 12px;
                perspective: 900px;
                text-align: center;
                border-radius: 5px;
            }
            .area-card,
            .front,
            .back {
                width: 230px;
                height: 95px;
                transition: all 0.2s ease-in-out;
            }
            .area-card {
                
                position: relative;
                overflow: hidden;
            }
            .front,
            .back {
                position: absolute;
                top: 0;
                left: 0;
                // border-radius: 5px;
            }
            .front {
                background: #0a415d;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                color: white;
            }
            .back {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-around;
                padding: 5px 10px;
                text-align: center;
                font-size: 12px;
                word-spacing: 0.5px;
                background-color: #ffe25d;
                color: #0a415d;
                cursor: default;
                transition: all 0.6s ease;

                p {
                    margin-top: 10px;
                    text-align: center;
                    padding: 0px 10px;
                    font-weight: 700 !important;
                }
                button {
                    line-height: 1;
                    font-weight: 700 !important;
                }
            }
            .area-card-container:hover .area-card .back {
                transform: translateY(0);
            }
            .back {
                transform: translateY(100%);
            }

            .icon {
                font-size: 1.3rem;
            }
            .title {
                text-transform: uppercase;
            }
            .text {
                white-space: pre-wrap;
                text-align: justify;
            }
            .area-card:hover {
                cursor: pointer;
                transform: scale(1.05);
                box-shadow: 0px 0px 10px -1px rgba(0, 0, 0, 0.75);
                -webkit-box-shadow: 0px 0px 10px -1px rgba(0, 0, 0, 0.75);
                -moz-box-shadow: 0px 0px 10px -1px rgba(0, 0, 0, 0.75);
            }
        }
    }
}

.row-dept {
    
   display: grid;
   grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    .deptTile {
        display: flex;
        // background-color: #0a415d;
        background-color: #ffe25d;
        padding: 5px 20px;
        margin: 10px;
        cursor: pointer;
    
        .deptTile-title {
            align-self: center;
            color: #0a415d;
            p {font-weight: bolder;}
            
        }
        transition: all 0.3s ease;
   
      
    }
    .active {
        background-color: #0a415d;
        .deptTile-title {
            color: white;
            p {font-weight: bolder;}
            
        }
    }
    .deptTile:hover {
        background-color: #0a415d;
        .deptTile-title {
            color: white;
            p {font-weight: bolder;}
            
        }
    }
}

/*                   GENERAL EFFECTS                     */
// --------------- BLANK SPACE EFFECT --------------------
.blank-space-enter {
    opacity: 0;
    transform: translateY(100%);
}
.blank-space-enter-active {
    opacity: 1;
    transform: translateY(0%);
    transition: opacity 0.5s, transform 0.5s ease-out 0s;
}
.blank-space-exit {
    opacity: 1;
    transform: translateY(0);
}
.blank-space-exit-active {
    opacity: 0;
    transform: translateY(100%);
    transition: opacity 0.5s, transform 0.5s ease-in 0s;
}
// --------------- ACADEMIC BOARD EFFECT --------------------
.academic-board-dpt-enter {
    opacity: 0;
    transform: translateY(100%);
}
.academic-board-dpt-enter-active {
    opacity: 1;
    transform: translateY(0%);
    transition: opacity 0.5s, transform 0.5s ease-out 0s;
}
.academic-board-dpt-exit {
    opacity: 0;
    transform: translateY(0%);
    transition: opacity 0.5s, transform 0.5s ease-in 0s;
}
.academic-board-dpt-exit-active {
    opacity: 0;
    transform: translateY(100%);
}

// --------------- DIRECTIVE BOARD EFFECT --------------------
.directive-board-dpt-enter {
    opacity: 0;
    transform: translateY(100%);
}
.directive-board-dpt-enter-active {
    opacity: 1;
    transform: translateY(0%);
    transition: opacity 0.5s, transform 0.5s ease-out 0s;
}
.directive-board-dpt-exit {
    opacity: 0;
    transform: translateY(0%);
    transition: opacity 0.5s, transform 0.5s ease-in 0s;
}
.directive-board-dpt-exit-active {
    opacity: 0;
    transform: translateY(100%);
}

// --------------- ESTUDENTS BOARD EFFECT --------------------
.students-board-dpt-enter {
    opacity: 0;
    transform: translateY(100%);
}
.students-board-dpt-enter-active {
    opacity: 1;
    transform: translateY(0%);
    transition: opacity 0.5s, transform 0.5s ease-out 0s;
}
.students-board-dpt-exit {
    opacity: 0;
    transform: translateY(0%);
    transition: opacity 0.5s, transform 0.5s ease-in 0s;
}
.students-board-dpt-exit-active {
    opacity: 0;
    transform: translateY(100%);
}

// --------------- ADMINISTRATIVE BOARD EFFECT --------------------
.administrative-board-dpt-enter {
    opacity: 0;
    transform: translateY(100%);
}
.administrative-board-dpt-enter-active {
    opacity: 1;
    transform: translateY(0%);
    transition: opacity 0.5s, transform 0.5s ease-out 0s;
}
.administrative-board-dpt-exit {
    opacity: 0;
    transform: translateY(0%);
    transition: opacity 0.5s, transform 0.5s ease-in 0s;
}
.administrative-board-dpt-exit-active {
    opacity: 0;
    transform: translateY(100%);
}

// --------------- HUMAN DEVELOPMENT EFFECT --------------------
.human-dev-dpt-enter {
    opacity: 0;
    transform: translateY(100%);
}
.human-dev-dpt-enter-active {
    opacity: 1;
    transform: translateY(0%);
    transition: opacity 0.5s, transform 0.5s ease-out 0s;
}
.human-dev-dpt-exit {
    opacity: 0;
    transform: translateY(0%);
    transition: opacity 0.5s, transform 0.5s ease-in 0s;
}
.human-dev-dpt-exit-active {
    opacity: 0;
    transform: translateY(100%);
}

// --------------- ENLACE EFFECT --------------------
.enlace-dpt-enter {
    opacity: 0;
    transform: translateY(100%);
}
.enlace-dpt-enter-active {
    opacity: 1;
    transform: translateY(0%);
    transition: opacity 0.5s, transform 0.5s ease-out 0s;
}
.enlace-dpt-exit {
    opacity: 0;
    transform: translateY(0%);
    transition: opacity 0.5s, transform 0.5s ease-in 0s;
}
.enlace-dpt-exit-active {
    opacity: 0;
    transform: translateY(100%);
}

// --------------- PHYSICAL PLANT EFFECT --------------------
.physical-plant-dpt-enter {
    opacity: 0;
    transform: translateY(100%);
}
.physical-plant-dpt-enter-active {
    opacity: 1;
    transform: translateY(0%);
    transition: opacity 0.5s, transform 0.5s ease-out 0s;
}
.physical-plant-dpt-exit {
    opacity: 0;
    transform: translateY(0%);
    transition: opacity 0.5s, transform 0.5s ease-in 0s;
}
.physical-plant-dpt-exit-active {
    opacity: 0;
    transform: translateY(100%);
}

// --------------- TECHNOLOGY EFFECT --------------------
.technology-dpt-enter {
    opacity: 0;
    transform: translateY(100%);
}
.technology-dpt-enter-active {
    opacity: 1;
    transform: translateY(0%);
    transition: opacity 0.5s, transform 0.5s ease-out 0s;
}
.technology-dpt-exit {
    opacity: 0;
    transform: translateY(0%);
    transition: opacity 0.5s, transform 0.5s ease-in 0s;
}
.technology-dpt-exit-active {
    opacity: 0;
    transform: translateY(100%);
}

// --------------- EDUCATIVE ADMIN EFFECT --------------------
.edu-admin-dpt-enter {
    opacity: 0;
    transform: translateY(100%);
}
.edu-admin-dpt-enter-active {
    opacity: 1;
    transform: translateY(0%);
    transition: opacity 0.5s, transform 0.5s ease-out 0s;
}
.edu-admin-dpt-exit {
    opacity: 0;
    transform: translateY(0%);
    transition: opacity 0.5s, transform 0.5s ease-in 0s;
}
.edu-admin-dpt-exit-active {
    opacity: 0;
    transform: translateY(100%);
}

/* DEPARTMENTS RESPONSIVE */
@media (max-width: 1400px) {
    .departments-inner {
        padding: 0px 2vw !important;
    }
}
@media (min-width: 1400px){
    .aboutus-slider .awssld {
        height: 35vh !important;
    }
}