/* CLUBES STYLES */

.clubes {
    width: 100%;
    height: 100%;
    margin-top: 50px;
    .clubes-inner {
        margin-top: 30px;
        padding: 0px 10vw;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .clubes-title {
            margin: 20px 0px;
            font-size: 1.2rem;
            font-weight: bold;
            text-transform: uppercase;
            color: #0a415d;
        }
        .clubes-text {
            p {
                color: #0a415d;
                font-weight: 300;
                font-size: 1rem;
                text-align: justify;
                white-space: pre-line;
            }
        }

        .row {   
            display: grid;
            grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
            margin-bottom: 20px;
        }

        
    @media (max-width: 700px) {
   
        .row {
            grid-template-columns: minmax(0,1fr) minmax(0,1fr);
        }
    }
    
    @media (max-width: 400px) {
       
        .row{
            grid-template-columns: minmax(0,1fr);
        }
    }
    }

    
}