/* Footer Styles */

.footer-wrapper {
  width: 100%;
  margin: 0;
  padding: 25px 15px 25px 15px;
  background: url("../../../assets/triangels-footer.svg") repeat fixed top;

  .footer-inner {
    height: 100%;
    display: flex;
    justify-content: space-between;
    background-color: var(--bg-footer);
    padding: 8px 0;

    .footer-icons {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      flex-basis: 40%;
    }
  }

  .logo-csb-footer {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    img {
      width: auto;
      height: 125px;
    }
  }

  .logo-ib-footer {
    // height: 100%;
    // width: 100px;
    display: flex;
    // flex-wrap: wrap;
    align-items: center;
    // flex-direction: row;
    // align-items: center;
    // justify-content: center;
    img {
      width: auto;
      height: 60px;
      margin-left: 1rem;
    }
  }

  .row.info {
    height: 100%;
    margin-left: 0px;
    margin-right: 0px;
    color: var(--text-color-footer);
  }

  .sub-info-inner {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    // flex-wrap: wrap;
    padding: 15px 10px;
    .sub-info-1 {
      font-weight: 300;
      p {
        margin-bottom: 0;
      }
    }
    .sub-info-2 {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      a > p {
        font-weight: initial;
        margin-bottom: 0;
      }
    }
  }

  p.address-2 {
    font-weight: lighter;
  }
}

@media (max-width: 768px) {
  .logo-ib-footer {
    padding: 10px 0px 15px 0px;
  }
  
  .footer-wrapper {
    .footer-inner {
      .footer-icons {
        display: flex;
        flex-basis: inherit;
      }
    }
  }
}

@media (min-width: 600px) {
  .sub-info-2 {
    margin: 15px 0;
  }
  .anthem_btn {
    margin-right: 0;
  }
}
.audio__player {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-family: "AleoLight";
  font-size: 14px;
  font-weight: 500;
  p {
    margin-bottom: 0 !important;
  }

  .anthem_btn {
    cursor: pointer;
  }
}
