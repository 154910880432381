.main-wrapper {
    background-color: var(--bg-transparent);
    width: 100%;
    margin: 0;
    margin-top: 10%;
    padding: 0;
    min-height: 100vh;

    /* General Styles */
    .titles {
        text-transform: uppercase;
        text-align: center;
        font-weight: 700;
        font-size: 2rem;
    }
}

/* RESPONSIVE MAIN */
@media (max-width: 780px) {
    div.main-wrapper > div.subpage {
        margin-top: 0px;
    }
}
