/* HUMAN DEVELOPMENT STYLES */

.human-dev-board {
    margin-bottom: 15vh;
    width: 100%;
    height: 100%;
    color: #0a415d;
    padding: 0px 15vw;
    .human-dev-board-inner {
        
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        // align-items: center;

        .human-dev-board-title {
            text-transform: uppercase;
            // margin: 20px 0px;
            font-size: 1.2rem;
            font-weight: bold;
            align-items: flex-start;
        }
        .human-dev-board-subtitle {
            margin-top: 20px;
            font-size: 1.2rem;
            font-weight: bold;
        }
        .human-dev-board-text {
            font-weight: lighter;
            font-size: 0.9rem;
            text-align: center;
            white-space: pre-line;
            margin-bottom: 20px;
            text-align: justify;
        }
        .human-dev-board-profiles-inner {
            width: 100%;
            height: 100%;
            // padding: 5px 0px;
            overflow: hidden;
            color: #0a415d;
            p{
                color: #0a415d;
            }
            div > div{
                justify-content: center;
            }
    }

    }
    
}

/* HUMAN DEVELOPMENT RESPONSIVE */
/* DEPARTMENTS RESPONSIVE */
@media (max-width: 1400px) {
    .human-dev-board  {
        padding:0 2vw
    }
}