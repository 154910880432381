/* News styles */
.news-inner {
    height: 100%;
    height: 100%;
    background-color: var(--bg-news);
    background-color: rgba(255, 255, 255, 0.91);
    margin: 0 0 0 0;

    .news-header {
        width: 100%;
        height: 100%;
        padding: 10px 0;
        color: var(--news-header-title);

        background-color: var(--bg-news);
    }
    .row.news-body {
        padding-left: 20px;
        padding-right: 20px;
    }
    .news-body {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;

        .new-image {
            display: flex;
            justify-content: center;
            align-items: center;
            object-fit: cover;
            overflow: hidden;
            width: 100%;
            max-height: auto;
            img {
                flex-shrink: 0;
                width: 100%;
                min-height: 100%;
            }
        }

        .new-info {
            position: relative;
            background-color: #ffe25d;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 10px 20px;
            color: var(--news-text);
            .info {
                padding-bottom: 10px;
                z-index: 3;
            }
            .info.curves {
                position: absolute;
                top: 0px;
                right: 0px;
                background-color: #e5cb54;
                width: 50%;
                height: 100%;
                clip-path: polygon(
                    100% 100%,
                    54% 100%,
                    55% 95%,
                    56% 92%,
                    58% 90%,
                    60% 87%,
                    64% 85%,
                    67% 84%,
                    71% 81%,
                    72% 79%,
                    73% 74%,
                    72% 70%,
                    71% 67%,
                    69% 62%,
                    66% 60%,
                    63% 58%,
                    59% 57%,
                    55% 57%,
                    52% 55%,
                    49% 52%,
                    48% 49%,
                    47% 43%,
                    47% 39%,
                    47% 36%,
                    48% 31%,
                    51% 28%,
                    54% 26%,
                    58% 25%,
                    62% 26%,
                    66% 26%,
                    70% 25%,
                    74% 23%,
                    77% 20%,
                    78% 16%,
                    77% 13%,
                    75% 10%,
                    73% 7%,
                    70% 5%,
                    66% 4%,
                    64% 0,
                    100% 0
                );
                z-index: 2;
            }
            .info.divisor {
                align-self: start;
                max-height: 0.5px;
                width: 50%;
                margin: 10px 0;
                background-color: #ffefab;
            }
            .info.divisor:after {
                position: relative;
                display: inline-block;
                top: -3px;
                content: "";
                width: 100%;
                height: 10px;
                background-color: #ffe25d;
            }
            .info.new-date {
                align-self: flex-start;
                text-align: left;
                font-style: italic;
                font-weight: lighter;
                font-size: 1.2rem;
            }
            .info.new-title {
                text-align: justify;
                font-weight: bold;
                font-size: 2rem;
            }
            .info.new-subtitle {
                text-align: justify;
                font-weight: lighter;
                font-size: 1.3rem;
                white-space: pre-line;
                color: #0a415d;
            }
        }
    }
    .row.news-tiles {
        margin-left: 0px;
        margin-right: 0px;
        background-color: #0a415d;
    }
    .news-tiles {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;

        .news-tiles-horizontal-bg {
            position: absolute;
            width: 100%;
            height: 120px;
            background-color: #6da093;
            bottom: 80px;
        }
        .new-tile {
            padding: 30px 30px;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .new-tile-img {
                z-index: 2;
                width: 220px;
                height: 220px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                background-color: "#0a415d";
                overflow: hidden;
                cursor: pointer;
                transition: all 0.2s ease-in-out;
                img {
                    flex-shrink: 0;
                    width: 150%;
                    min-height: 100%;
                    object-fit: cover;
                }
            }
            .new-tile-number:after {
                content: "";
                position: absolute;
                display: inline-block;
                height: 100px;
                width: 50px;
                border-bottom-left-radius: 100px;
                border-top-left-radius: 100px;
                background-color: #e5cb54;
                transform: rotate(180deg);
                right: 0;
                z-index: 3;
            }
            .new-tile-number {
                z-index: 2;
                position: relative;
                top: -50px;
                width: 100px;
                height: 100px;
                border-radius: 50%;
                background-color: #ffe25d;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #616161;
                font-weight: bolder;
                font-size: 3rem;
                cursor: pointer;
                span {
                    z-index: 4;
                }
                transition: all 0.2s ease-in-out;
            }
            .new-tile-text {
                position: relative;
                top: -20px;
                height: 100%;
                width: 100%;
                background-color: #ffffff;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 10px 35px;
                font-size: 1.5rem;
                white-space: pre-wrap;
                text-align: center;
                cursor: pointer;
                transition: all 0.2s ease-in-out;
            }
        }
        .new-tile-img:hover {
            transform: scale(1.05);
            box-shadow: 0px -1px 29px -1px rgba(0, 0, 0, 0.75);
            -webkit-box-shadow: 0px -1px 29px -1px rgba(0, 0, 0, 0.75);
            -moz-box-shadow: 0px -1px 29px -1px rgba(0, 0, 0, 0.75);
        }
        .new-tile-number:hover {
            transform: scale(1.05) translateY(-5px);
            box-shadow: 0px -1px 29px -1px rgba(0, 0, 0, 0.75);
            -webkit-box-shadow: 0px -1px 29px -1px rgba(0, 0, 0, 0.75);
            -moz-box-shadow: 0px -1px 29px -1px rgba(0, 0, 0, 0.75);
        }
        .new-tile-text:hover {
            transform: scale(0.95) translateY(-5px);
            box-shadow: 0px -1px 29px -1px rgba(0, 0, 0, 0.75);
            -webkit-box-shadow: 0px -1px 29px -1px rgba(0, 0, 0, 0.75);
            -moz-box-shadow: 0px -1px 29px -1px rgba(0, 0, 0, 0.75);
        }
    }
}

@media (max-width: 500px) {
    .info.new-date {
        font-size: 1rem !important;
    }
    .info.new-title {
        font-size: 1.8rem !important;
    }
    .info.new-subtitle {
        font-size: 1.2rem !important;
    }
}

.news__body {
    width: auto;
    height: auto;
    position: absolute;
    top:50vh;
    left:40vw;
    align-self: start;
    font-size:14;
    color: #fff;
}

.news__title {
    width: auto;
    height: auto;
    position: absolute;
    top:40vh;
    left:40vw;
    align-self: start;
    font-size:14;
    color: #fff;
}


.news__heading {
     width: auto;
    height: auto;
    position: absolute;
    top:20vh;
    left:40vw;
    align-self: start;
    font-size:14;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    color: #fee860;
}

// @media (max-width: 800px) {
   
//     .news__body {
//         height: 200%;
//         width: 100%;
//         .react-multi-carousel-list{
//             height: 350px;
//         }
//         .bottom-margin{
//             height: 50px !important;
//         }
//     }
// }