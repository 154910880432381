.row.activities-body {
    margin-left: 0px;
    margin-right: 0px;
    padding-bottom: 20vh;
}
.activities-body {
    width: 100%;
    height: auto;
    overflow: hidden;
    
}

.subpage {
    margin: auto;
}

// /*                   GENERAL EFFECTS                     */ 
// --------------- FERIA CULTURAL SUBAREA EFFECT --------------------
.feriaCultural-subarea-enter {
    opacity: 0;
    transform: translateX(-100%);
}
.feriaCultural-subarea-enter-active {
    opacity: 1;
    transform: translateX(0%);
    transition: opacity 0.5s, transform 0.5s ease-out 0s;
}
.feriaCultural-subarea-exit {
    opacity: 0;
    transform: translateX(0%);
    transition: opacity 0.5s, transform 0.5s ease-in 0s;
}
.feriaCultural-subarea-exit-active {
    opacity: 0;
    transform: translateX(100%);
}

// --------------- GOZABEQUE SUBAREA EFFECT --------------------
.gozabeque-subarea-enter {
    opacity: 0;
    transform: translateX(-100%);
}
.gozabeque-subarea-enter-active {
    opacity: 1;
    transform: translateX(0%);
    transition: opacity 0.5s, transform 0.5s ease-out 0s;
}
.gozabeque-subarea-exit {
    opacity: 0;
    transform: translateX(0%);
    transition: opacity 0.5s, transform 0.5s ease-in 0s;
}
.gozabeque-subarea-exit-active {
    opacity: 0;
    transform: translateX(100%);
}

// --------------- MUSICAL SUBAREA EFFECT --------------------
.musical-subarea-enter {
    opacity: 0;
    transform: translateX(-100%);
}
.musical-subarea-enter-active {
    opacity: 1;
    transform: translateX(0%);
    transition: opacity 0.5s, transform 0.5s ease-out 0s;
}
.musical-subarea-exit {
    opacity: 0;
    transform: translateX(0%);
    transition: opacity 0.5s, transform 0.5s ease-in 0s;
}
.musical-subarea-exit-active {
    opacity: 0;
    transform: translateX(100%);
}

// --------------- CLUBES SUBAREA EFFECT --------------------
.clubes-subarea-enter {
    opacity: 0;
    transform: translateX(-100%);
}
.clubes-subarea-enter-active {
    opacity: 1;
    transform: translateX(0%);
    transition: opacity 0.5s, transform 0.5s ease-out 0s;
}
.clubes-subarea-exit-active {
    opacity: 1;
    transform: translateX(0%);
}
.clubes-subarea-exit {
    opacity: 0;
    transform: translateX(100%);
    transition: opacity 0.5s, transform 0.5s ease-in 0s;
}

// --------------- OPTATIVAS SUBAREA EFFECT --------------------
.optativas-subarea-enter {
    opacity: 0;
    transform: translateX(-100%);
}
.optativas-subarea-enter-active {
    opacity: 1;
    transform: translateX(0%);
    transition: opacity 0.5s, transform 0.5s ease-out 0s;
}
.optativas-subarea-exit {
    opacity: 0;
    transform: translateX(0%);
    transition: opacity 0.5s, transform 0.5s ease-in 0s;
}
.optativas-subarea-exit-active {
    opacity: 0;
    transform: translateX(100%);
}

// --------------- MNU SUBAREA EFFECT --------------------
.mnu-subarea-enter {
    opacity: 0;
    transform: translateX(-100%);
}
.mnu-subarea-enter-active {
    opacity: 1;
    transform: translateX(0%);
    transition: opacity 0.5s, transform 0.5s ease-out 0s;
}
.mnu-subarea-exit {
    opacity: 0;
    transform: translateX(0%);
    transition: opacity 0.5s, transform 0.5s ease-in 0s;
}
.mnu-subarea-exit-active {
    opacity: 0;
    transform: translateX(100%);
}