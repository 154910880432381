/* Cocurriculars carrousel styles */

.directors-cards-carousel {
    height: 100%;
    padding: 20px 0 30px 0;
    overflow: hidden;

    button.react-multiple-carousel__arrow {
        z-index: 9;
    }
    .directors-cards-inner {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .react-multi-carousel-item,z
    .react-multi-carousel-item--active {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .react-multi-carousel-list {
        overflow: inherit;
    }
    .react-multiple-carousel__arrow--right,
    .react-multiple-carousel__arrow--left{
        background: #0a415d;
    }
    .react-multiple-carousel__arrow--right:hover,
    .react-multiple-carousel__arrow--left:hover{
        background: #ffe25d;
    }
    .directors-card {
        width: 100%;
        // height: 100%;
        color: #0a415d;

        .card-inner {
            width: 70vw;
            height: 100%;
            .row {
                margin: 0;
                align-items: flex-start;
            }
            // border: 2px solid #0a415d;
            border-radius: 3px;
            overflow: hidden;
            // background-color: rgba(0, 0, 0, 1);
            // box-shadow: 20px 20px 18px -1px rgba(0, 0, 0, 0.27);
            // -webkit-box-shadow: 20px 20px 18px -1px rgba(0, 0, 0, 0.27);
            // -moz-box-shadow: 20px 20px 18px -1px rgba(0, 0, 0, 0.27);

            .card-left {
                // padding: 10px;
                width: 100%;
                margin-bottom: 5vh;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                color: white;
                // background-color: #0a415d;
                .card-image {
                    overflow: hidden;
                    // margin: 30px 0;
                    width: 200px;
                    height: 200px;
                    // border-radius: 50%;
                    // box-shadow: 0px 0px 5px 12px rgba(0, 0, 0, 0.27);
                    // -webkit-box-shadow: 0px 0px 5px 12px rgba(0, 0, 0, 0.27);
                    // -moz-box-shadow: 0px 0px 5px 12px rgba(0, 0, 0, 0.27);
                    img {
                        height: 100%;
                        width: 100%;
                        object-position: center;
                        object-fit: cover;
                    }
                    // .yellow-circle {
                       
                    //     position: absolute;
                    //     left: 0.9vw;
                    //     top: 20vh;
                    //     width: 20vh;
                    //     border-radius: 50%;
                    //     background-color: yellow;
                    //     opacity: 0.6;
                    //     z-index: 999;
                    //     height: 20vh;
                    // }
                    .yellow-circle {
                       
                        position: absolute;
                        left: 13%;
                        top: 53%;
                        width: 12vh;
                        height: 12vh;
                        border-radius: 50%;
                        background-color: #ffe25d;
                        opacity: 0.7;
                        z-index: 999;
                        
                    }
                }
                

                
            }
            .card-right {
                padding: 10px;
                width: 100%;
                height: 100%;
                min-height: 300px;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                
                // background-color: whitesmoke;
                .info-profession {
                    padding: 0px 10px;
                    text-align: justify;
                    white-space: pre-line;
                }

                .card-name {
                    justify-self: flex-start;
                    text-transform: uppercase;
                    font-weight: 800;
                    text-align: center;
                    padding-left: 10px;
                }
                .card-charge {
                    text-transform: uppercase;
                    font-weight: 600;
                    padding-left: 10px;
                }
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .card-image > .yellow-circle {
        display: none;
    }
    .row-dept {
        grid-template-columns: minmax(0,1fr);
    }
}
