/* STYLES FOR IB-BACHELOR */
.ib-bachelor {
    .ib-bachelor-inner {
        margin-bottom: 15vh;
        padding: 0px 10vw;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        // align-items: center;

        .ib-bachelor-title {
            text-transform: uppercase;
            margin: 20px 0px;
            margin-top: 90px;
            font-size: 1.2rem;
            font-weight: bold;
            color: #0a415d;
        }
        .ib-bachelor-subtitle {
            margin-top: 20px;
            font-size: 1.2rem;
            font-weight: bold;
        }
        .ib-bachelor-text {
             
            p {
                color: #0a415d;
                font-weight: 300;
                font-size: 1rem;
                text-align: justify;
                white-space: pre-line;
            }
        }

        .content-ib {

            .logo-ib {
                display: flex;
                justify-content: center;
                img  {
                    width:300px;
                }
                margin-bottom: 30px;

            }
        }
    }
    .files {
        margin-top: 50px;
        width: 100%;
        height: 100%;
        .row {
            margin: 0;
        }
        h6 {
            text-align: center;
        }
        .file-col {
            display: flex;
            justify-content: center !important;
            align-items: center !important;
        }
        .file {
            width: 90%;
            height: 80%;
            padding: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #0a415d;
            color: white;
            transition: all 200ms ease;
        }
        .file:hover {
            cursor: pointer;
            transform: scale(1.05);
            box-shadow: 0px 0px 10px -1px rgba(0, 0, 0, 0.75);
            -webkit-box-shadow: 0px 0px 10px -1px rgba(0, 0, 0, 0.75);
            -moz-box-shadow: 0px 0px 10px -1px rgba(0, 0, 0, 0.75);
        }
    }
    .pdf-viewer{
        width: 100%;
    }
}
