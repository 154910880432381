.students-board-courses-table {
    width: 100%;
    height: 100%;
    padding: 10px 5vw;
    .MuiTableHead-root {
        background-color: #0a415d;
        .MuiTableRow-root > th {
            color: white;
        }
    }
}
