/* Modify slider's arrows style */
.new-slider {

   cursor: pointer;
    // margin-top: var(--main-slider-margin-top);
    
    .awssld__bullets{
        bottom: -20px;
    }
    .awssld__bullets button{
        z-index: 9;
        width: 10px;
        height: 10px;
        opacity: 0.5;
        margin: 3px;
    }
    // .awssld {
        //height: var(--main-slider);
        // border-bottom: 2px solid #0a415d;
    // }
    .awssld__timer {
        opacity: 0 !important;
        pointer-events: none !important; /* prevent events (mouse clicking etc.) */
    }
    .awssld__controls button {
        top: calc(60% - (0.5 * var(--control-button-height-n)));
    }
    .awssld__controls__arrow-left::before,
    .awssld__controls__arrow-left::after,
    .awssld__controls__arrow-right::before,
    .awssld__controls__arrow-right::after {
        background-color: var(--organic-arrow-color-n) !important;
        width: var(--organic-arrow-thickness-n) !important;
        border-radius: var(--organic-arrow-border-radius-n) !important;
    }
    .awssld__container {
        padding-bottom: var(--slider-height-percentage-n) !important;
    }
    .slider-link {
        position: relative;
        display: block;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }
    .awssld__content {
        img {
            object-fit: fill !important;
        }
    }

    // .news__container {
    //     // width: 100%;
    //     text-align: center;
    //     align-items: center;
    //     margin:0 20;
    // }

    .news__body {
       p {
        max-width: fit-content;
        font-size: 0.9rem;
       }
    }

 
    padding: 0rem 5%;

    
.news__body {
    width: auto;
    height: auto;
    position: absolute;
    top:30vh;
    left:40vw;
    align-self: start;
    font-size:14;
    color: #fff;
    text-align: start;
   p {
    max-width: 70%;
    max-height: 100%;
   }
}

.news__title {
    width: auto;
    height: auto;
    position: absolute;
    top:25vh;
    left:40vw;
    align-self: start;
    font-size:14;
    color: #fff;
}


.news__heading {
    width: auto;
    height: auto;
    position: absolute;
    top:10vh;
    left:40vw;
    align-self: start;
    font-size:14;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    color: #fee860;
}

    
}

// @media (min-width: 668px) and (max-width: 992px) {

//     .new-slider {
//         max-height: 250px;
//         .awssld {
//              height: 250px;
//         }
//         .awssld__wrapper{
//             max-height: 250px;
//         }
        
//         .news__heading  {
//             width: auto;
//             height: auto;
//             position: absolute;
//             top:3vh;
//             left:35vw;
//             align-self: start;
//             font-size:2rem;
//             font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
//             // color: #fee860;
//             color: green;

//             }

//             .awssld__wrapper{
//                 max-height: 300px;
//             }
//             max-height: 300px;

//             .news__body {

//                 top:10vh;
//                 left:35vw;
//                 p {
//                     text-overflow: ellipsis;
//                     font-size:0.9rem;
//                 }
//             }
//             .news__title {
//                 top:7vh;
//                 left:35vw;
//                 font-size:1.3rem;
//             }
           
        
//     }
    

    
// }

// @media (max-width: 668px) {
   
//     .new-slider {
//         .awssld {
//             height: 200px;
//        }
//         .news__heading  {
//             top:3vh;
//             left:35vw;
//             font-size:1.3rem;
            
//             color: brown;
//             }

//             .news__title {
//                 top:8vh;
//                 left:35vw;
//                 font-size:0.8rem;
//             }

//             .news__body {
//                 top:11vh;
//                 left:35vw;
                
                
//                 p {
//                     text-overflow: ellipsis;
//                     font-size:0.6rem;
//                 }
               
//             }
            
           
        
//     }

  
    
// }

// @media (min-width:  992px){
//     .new-slider {
//         .news__heading  {
//             color: aqua;
//         }
//         .awssld {
//             height: 430;
//         }
//         .awssld__wrapper{
//             max-height:430px;
//         }
//         .news__heading  {
//             top:6vh;
//             left:37vw;
//             font-size:2.5rem;
//             // color: brown;
//             }

//             .news__title {
//                 top:14vh;
//                 left:37vw;
//                 font-size:1.5rem;
//             }

//             .news__body {
//                 top:19vh;
//                 left:37vw;
               
                
//                 p {
//                     text-overflow: ellipsis;
//                     font-size:1.1rem;
//                 }
               
//             }

//     }
// }

/* Media Query for Mobile Devices */
@media (max-width: 360px) {
    .new-slider {
        .awssld {
            height: 150px;
       }
        .news__heading  {
            top:2vh;
            left:35vw;
            font-size:1.2rem;
            
            // color: brown;
            }

            .news__title {
                top:5.5vh;
                left:35vw;
                font-size:0.8rem;
            }

            .news__body {
                top:8vh;
                left:35vw;
                
                
                p {
                    text-overflow: ellipsis;
                    font-size:0.6rem;
                }
               
            }
            
           
        
    }

}
 
/* Media Query for low resolution  Tablets, Ipads */
@media (min-width: 361px) and (max-width: 767px) {
    .new-slider {
        .awssld {
            height: 180px;
       }
        .news__heading  {
            top:3vh;
            left:35vw;
            font-size:1.3rem;
            
            // color: orange;
            }

            .news__title {
                top:7vh;
                left:35vw;
                font-size:0.8rem;
            }

            .news__body {
                top:9vh;
                left:35vw;
                
                
                p {
                    text-overflow: ellipsis;
                    font-size:0.6rem;
                }
               
            }
            
           
        
    }
}
 
/* Media Query for Tablets Ipads portrait mode */
@media (min-width: 768px) and (max-width: 1024px){
    .new-slider {
        max-height: 270px;
        .awssld {
             height: 270px;
        }
        .awssld__wrapper{
            max-height: 270px;
        }
        .awssld__content {
            background-color: #fff;
        }
        
        .news__heading  {
            width: auto;
            height: auto;
            position: absolute;
            top:3vh;
            left:35vw;
            align-self: start;
            font-size:2rem;
            font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
            // color: green;

            }

            .awssld__wrapper{
                max-height: 300px;
            }
            max-height: 300px;

            .news__body {

                top:10vh;
                left:35vw;
                p {
                    text-overflow: ellipsis;
                    font-size:0.9rem;
                }
            }
            .news__title {
                top:7vh;
                left:35vw;
                font-size:1.3rem;
            }
           
        
    }
}
 
/* Media Query for Laptops and Desktops */
@media (min-width: 1025px) and (max-width: 1280px){
    .new-slider {
        // .news__heading  {
            // color: aqua;
        // }
        .awssld {
            height: 430;
        }
        .awssld__wrapper{
            max-height:430px;
        }
        .news__heading  {
            top:6vh;
            left:37vw;
            font-size:2.5rem;
            // color: brown;
            }

            .news__title {
                top:14vh;
                left:37vw;
                font-size:1.5rem;
            }

            .news__body {
                top:19vh;
                left:37vw;
               
                
                p {
                    text-overflow: ellipsis;
                    font-size:1.1rem;
                }
               
            }

    }
}
 
/* Media Query for Large screens */
@media (min-width: 1281px) {
    .new-slider {
        // .news__heading  {
            // color: purple;
        // }
        .awssld {
            height: 430;
        }
        .awssld__wrapper{
            max-height:430px;
        }
        .news__heading  {
            top:6vh;
            left:37vw;
            font-size:2.5rem;
            // color: brown;
            }

            .news__title {
                top:14vh;
                left:37vw;
                font-size:1.5rem;
            }

            .news__body {
                top:19vh;
                left:37vw;
               
                
                p {
                    text-overflow: ellipsis;
                    font-size:1.1rem;
                }
               
            }

    }
}



