// .areas {
//     width: 100%;
//     height: 100%;
//     padding: 0px 0px;
//     margin-top: 15px;
//     position: relative;
//     z-index: 8;
//     .row {
//         width: 100%;
//         margin: 0;
//         justify-content: center;
//     }
//     .col-card {
//         width: 100%;
//         height: 100%;
//         display: flex;
//         padding: 10px 5px;
//         justify-content: center;
//         align-items: center;
//     }
//     .area-title {
//         position: absolute;
//         background-color: white;
//         padding: 0px 10px;
//         top: 0%;
//         left: 50%;
//         transform: translate(-50%, -50%);
//         text-transform: uppercase;
//     }
//     .area-card-container {
//         display: inline-block;
//         margin: 0 auto;
//         padding: 0 12px;
//         perspective: 900px;
//         text-align: center;
//         border-radius: 5px;
//     }
//     .area-card,
//     .front,
//     .back {
//         width: 230px;
//         height: 95px;
//         transition: all 0.2s ease-in-out;
//     }
//     .area-card {
        
//         position: relative;
//         overflow: hidden;
//     }
//     .front,
//     .back {
//         position: absolute;
//         top: 0;
//         left: 0;
//         // border-radius: 5px;
//     }
//     .front {
//         background: #0a415d;
//         display: flex;
//         flex-direction: column;
//         align-items: center;
//         justify-content: center;
//         color: white;
//     }
//     .back {
//         display: flex;
//         flex-direction: column;
//         align-items: center;
//         justify-content: space-around;
//         padding: 5px 10px;
//         text-align: center;
//         font-size: 12px;
//         word-spacing: 0.5px;
//         background-color: #ffe25d;
//         color: #0a415d;
//         cursor: default;
//         transition: all 0.6s ease;

//         p {
//             margin-top: 10px;
//             text-align: center;
//             padding: 0px 10px;
//             font-weight: 700 !important;
//         }
//         button {
//             line-height: 1;
//             font-weight: 700 !important;
//         }
//     }
//     .area-card-container:hover .area-card .back {
//         transform: translateY(0);
//     }
//     .back {
//         transform: translateY(100%);
//     }

//     .icon {
//         font-size: 1.3rem;
//     }
//     .title {
//         text-transform: uppercase;
//     }
//     .text {
//         white-space: pre-wrap;
//         text-align: justify;
//     }
//     .area-card:hover {
//         cursor: pointer;
//         transform: scale(1.05);
//         box-shadow: 0px 0px 10px -1px rgba(0, 0, 0, 0.75);
//         -webkit-box-shadow: 0px 0px 10px -1px rgba(0, 0, 0, 0.75);
//         -moz-box-shadow: 0px 0px 10px -1px rgba(0, 0, 0, 0.75);
//     }
// }

// .row-dept {
    
//     display: grid;
//     grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
     .activityTile {
         display: flex;
         // background-color: #0a415d;
         background-color: #ffe25d;
         padding: 5px 20px;
         align-items: center;
        //  margin: 10px;
         cursor: pointer;
     
         .activity-title {
             color: #0a415d;
             p {font-weight: bolder;}
             
         }
         transition: all 0.3s ease;
    
       
     }
     .active {
         background-color: #0a415d;
         .activity-title {
             color: white;
             p {font-weight: bolder;}
             
         }
     }
     .activityTile:hover {
         background-color: #0a415d;
         .activity-title {
             color: white;
             p {font-weight: bolder;}
             
         }
     }
//  }
 