/* ACADEMIC BOARD STYLES */

// .academic-board {
//     margin-bottom: 15vh;
//     width: 100%;
//     height: 100%;
//     .academic-board-inner {
//         margin-top: 30px;
//         padding: 0px 15vw;
//         width: 100%;
//         height: 100%;
//         display: flex;
//         flex-direction: column;
//         justify-content: center;
//         align-items: center;

//         .academic-board-title {
//             text-transform: uppercase;
//             margin: 20px 0px;
//             font-size: 1.2rem;
//             font-weight: bold;
//         }
//         .academic-board-subtitle {
//             margin-top: 20px;
//             font-size: 1.2rem;
//             font-weight: bold;
//         }
//         .academic-board-text {
//             p {
//                 color: black;
//                 font-weight: 300;
//                 font-size: 1rem;
//                 text-align: justify;
//             }
//         }

//         .board-division {
//             margin: 30px 0px;
//             width: 100%;
//             height: 100%;
//             .row {
//                 width: 100%;
//                 margin: 0;
//             }
//             .directive-team-inner,
//             .coordinators-inner,
//             .support-inner {
//                 width: 100%;
//                 height: 100%;
//                 background-color: #0a415d;
//                 text-transform: uppercase;
//                 white-space: pre-wrap;
//                 display: flex;
//                 justify-content: center;
//                 align-items: center;
//                 cursor: pointer;
//                 transition: all 0.4s ease;
//                 text-align: center;
//             }
//             .directive-team-inner:hover,
//             .coordinators-inner:hover,
//             .support-inner:hover {
//                 background-color: rgba(10, 65, 93, 0.8);
//             }
//             .directive-team,
//             .coordinators,
//             .support {
//                 width: 100%;
//                 height: 100px;
//                 color: white;
//                 padding: 15px 40px;
//                 font-size: 1.2rem;
//             }
//             .directive-team,
//             .coordinators,
//             .support {
//                 padding: 10px;
//             }
//             .coordinators-inner{
//                 padding: 0px 10px;
//             }
//         }
//     }
//     .academic-board-profiles-inner {
//         width: 100%;
//         height: 100%;
//         padding: 5px 0px;
//         overflow: hidden;
//     }
// }

// /* ACADEMIC BOARD RESPONSIVE */
// @media (max-width: 576px) {
//     .board-division {
//         .directive-team,
//         .coordinators {
//             height: 100px !important;
//             padding: 10px !important;
//         }
//     }
// }
// @media (max-width: 900px) {
//     .board-division {
//         .directive-team,
//         .coordinators,
//         .support {
//             font-size: 1rem !important;
//         }
//     }
// }
// @media (max-width: 768px) {
//     .board-division {
//         .directive-team,
//         .coordinators {
//             font-size: 0.9rem !important;
//         }
//     }
// }

.academic-container {
    padding: 0 15vw;
    color: #0a415d;

        .academic-board-title {
            text-transform: uppercase;
            margin: 20px 0px;
            font-size: 1.2rem;
            font-weight: bold;
        }
        .academic-board-subtitle {
            margin-top: 20px;
            font-size: 1.2rem;
            font-weight: bold;
        }
        .academic-board-text {
            margin-bottom: 5vh;
            p {
                color: #0a415d;
                // font-weight: 300;
                font-size: 1rem;
                text-align: justify;
            }
        }
}

/* DEPARTMENTS RESPONSIVE */
@media (max-width: 1400px) {
    .academic-container {
        padding:0 2vw
    }
}


