.profile-card {
    transition: all 300ms ease;
    .MuiPaper-root {
        overflow: hidden !important;
    }
    .MuiPaper-root > img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: top;
    }
    .MuiGrid-item {
        margin: 16px;
    }
    .profile-card-inner{
        scale: (1);
        transition: all 300ms ease;
    }

    .profile-card-inner:hover{
        cursor: pointer;
        transform: scale(1.03);
        box-shadow: 0px 0px 15px -1px rgba(0, 0, 0, 0.75);
        -webkit-box-shadow: 0px 0px 15px -1px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 0px 0px 15px -1px rgba(0, 0, 0, 0.75);
    }

}

