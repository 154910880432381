/* DIRECTORS BOARD STYLES */

.directors-board {
    margin-bottom: 15vh;
    width: 100%;
    height: 100%;
    color: #0a415d;
    .mission-vision.row {
        width: 100%;
    }
    .directors-board-inner {
        margin-top: 30px;
        // padding: 0px 15vw;
        padding: 0px 15vw 50px 15vw;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .directors-board-title {
            text-transform: uppercase;
            margin: 20px 0px;
            font-size: 1.2rem;
            font-weight: bold;
            align-items: flex-start;
        }
        .directors-board-subtitle {
            margin-top: 20px;
            font-size: 1.2rem;
            font-weight: bold;
        }
        .directors-board-text {
            // font-weight: lighter;
            font-size: 1rem;
            text-align: justify;
        }
    }
}

/* DIRECTORS BOARD RESPONSIVE */
// @media(max-width: 1200px) {
//     .philosophy-inner {
//         padding: 0px 5vw !important;
//     }
// }
