// /* Modify slider's arrows style */
.default-slider {
    height: 100%;
    width: 100%;
    margin: 0px 0px;
    // padding: 2vh 0;
    // .awssld__controls{
    //     display: none;
    // }
    // .awssld__controls button {
    //     z-index: 1;
    // }
    .awssld__bullets{
        bottom: -25px;
    }
    .awssld__bullets button{
        z-index: 9;
        width: 10px;
        height: 10px;
        opacity: 0.2;
        margin: 3px;
    }

    .awssld__controls button {
        top: calc(60% - (0.5 * 50%)) !important;
    }
    .awssld {
        height: 54vh;
    }
    .awssld::after {
        position: absolute;
        content: "";
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 8;
        // margin: 0 auto;
    }
    .slider-overlay {
        position: relative;
        display: block;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }
    img{
        object-fit: cover !important;
    }
}
