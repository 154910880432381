/* Cocurriculars carrousel styles */

.item-cards-carousel {
    height: 100%;
    padding: 20px 0 15vh 0;
    overflow: hidden;

    button.react-multiple-carousel__arrow {
        z-index: 9;
    }
    .item-cards-inner {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .react-multi-carousel-item,z
    .react-multi-carousel-item--active {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .react-multi-carousel-list {
        overflow: inherit;
    }
    .react-multiple-carousel__arrow--right,
    .react-multiple-carousel__arrow--left{
        background: #0a415d;
     
    }
    .react-multiple-carousel__arrow--right:hover,
    .react-multiple-carousel__arrow--left:hover{
        background: #ffe25d;
    }
    .item-card {
       cursor: pointer;
      
       
       .card-content {
        
       
        .card-name {
            font-weight: bolder;
            font-family: "AleoRegular";
            font-size: 0.98rem;
            line-height: normal;
            padding-top: 5%;
        }
 
        .card-charge {
         font-size: 0.78rem;
         line-height: normal;
        }
       }
    }
}

